import React from 'react';
import './Modal.css'; // Import your CSS for styling

const Modal = ({ isOpen, onClose, notification, onDecision }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2 style={{color:'#5936f4'}}>Notification</h2>
                <p>{notification.message}</p>
                <div className="modal-actions">
                    <button style={{backgroundColor:'green', color:'white'}} onClick={() => onDecision(notification.id, 'accept')}>Accept</button>
                    <button style={{backgroundColor:'red', color:'white'}} onClick={() => onDecision(notification.id, 'reject')}>Reject</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
