import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import './aboutUs.css';

function AboutUs({ setIsAboutUsVisible }) {

    const screenWidth = window.innerWidth;

    return (
        <div className='AboutUsPage'>
            <div className='AboutUs'>
                <div onClick={() => setIsAboutUsVisible(false)} className='closeAboutUs'>X</div>
                <Scrollbars
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={(screenWidth > 500) ? 500 : 600}
                    renderThumbVertical={({ style, ...props }) => (
                        <div
                            {...props}
                            style={{
                                ...style,
                                backgroundColor: 'black',
                                borderRadius: '10px',
                                width: '10px',
                            }}
                        />
                    )}
                    renderTrackVertical={({ style, ...props }) => (
                        <div
                            {...props}
                            style={{
                                ...style,
                                backgroundColor: 'black',
                                borderRadius: '10px',
                                width: '10px',
                            }}
                        />
                    )}
                >
                    <h1 id='headingOfAboutUs'>About Us</h1>
                    <p className='normalTextAboutUs'>
                    Welcome to ADVENTROZ, a travel company dedicated to providing enriching and unforgettable travel experiences. Our founder, Nizaam Khan, is committed to bringing history to life and ensuring that your explorations of iconic destinations are both educational and entertaining.
                    </p>
                    <p  className='normalTextAboutUs'>
                    Our mission at ADVENTROZ is to enhance your travel experience by offering expertly curated tour guides and immersive audiobooks that showcase the rich history, culture, and hidden gems of each destination. We believe that every place has a story to tell, and we are here to help you uncover it.
                    </p>
                    <h2 className='subHeading'>What We Offer : </h2>
                    <ul className='aboutUsUl'>
                        <li className='aboutUsLi'>
                            <h3 className='subSubHead'>Expert Tour Guides :</h3>
                            <p  className='normalTextAboutUs'>
                            Our team of knowledgeable and passionate tour guides is prepared to lead you through the most captivating landmarks, offering insights and stories that delve beyond the surface.
                            </p>
                        </li>
                        <li className='aboutUsLi'>
                            <h3 className='subSubHead'>Engaging Audiobooks :</h3>
                            <p  className='normalTextAboutUs'>
                            Enjoy the flexibility to explore at your own pace with our professionally produced audiobooks, filled with historical facts, captivating narratives, and interesting trivia.
                            </p>
                        </li>
                        <li className='aboutUsLi'>
                            <h3 className='subSubHead'>Customized Tours :</h3>
                            <p  className='normalTextAboutUs'>
                            : Whether your interests lie in art, history, architecture, or local culture, we offer personalized tours tailored to your preferences and schedule.
                            </p>
                        </li>
                    </ul>
                    <h2 className='subHeading'>Why Choose Us?</h2>
                    <ul className='aboutUsUl'>
                        <li className='aboutUsLi'>
                            <h3 className='subSubHead'>Experienced Professionals :</h3>
                            <p  className='normalTextAboutUs'>
                            Our guides are experts in their respective fields, ensuring that you receive accurate and compelling information throughout your journey.
                            </p>
                        </li>
                        <li className='aboutUsLi'>
                            <h3 className='subSubHead'>High-Quality Content :</h3>
                            <p  className='normalTextAboutUs'>
                            Our audiobooks are meticulously created to provide a seamless and enriching listening experience.
                            </p>
                        </li>
                        <li className='aboutUsLi'>
                            <h3 className='subSubHead'>Customer-Centric Approach :</h3>
                            <p  className='liTextAboutUs'>
                            Your satisfaction is our priority, and we are dedicated to delivering exceptional service every step of the way.
                            </p>
                        </li>
                    </ul>
                    <h2 className='subHeading'>Join Us on a Journey</h2>
                    <p  className='normalTextAboutUs'>
                        At ADVENTROZ, we are dedicated to making your travels more meaningful and enjoyable. Let us be your guide as you uncover the stories and secrets of the world's most remarkable destinations.
                    </p>
                </Scrollbars>
            </div>
        </div>
    );
}

export default AboutUs;
