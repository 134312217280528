import React,{useEffect, useState} from 'react';
import './TourGuideReview.css';

function TourGuideReview({dashboardOpener}) {
    const [reviews, setReviews] = useState([]);
    const [averageRating,setAverageRating]=useState(0);
    const userEmailAddress=useState(localStorage.getItem('loggedInEmail'));

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await fetch(`/api/reviews/${userEmailAddress[0]}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setReviews(data);

            } catch (error) {
                
            }
        };

        fetchReviews();
    }, []);

        const renderStars = (rating) => {
            const totalStars = 5;
            const filledStars = '★'.repeat(rating);
            const emptyStars = '☆'.repeat(totalStars - rating);
            return filledStars + emptyStars;
          };

        useEffect(()=>{
            if(reviews.length===0){
                setAverageRating(0);
                return;
            }
            const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
            const average =  Math.round(totalRating / reviews.length);
            setAverageRating(average);
            localStorage.setItem('averageReviews',average)
        },[reviews,averageRating])


  return (
    <section id='tour-guide-review-page'>
      <div className='tour-guide-top-nav-bar'>
            <button 
                style={{marginTop:'20px'}}
                onClick={dashboardOpener} 
                className='tour-operator-dashboard-opener'
            >&#9776;</button>
      </div>
      <div id='review-profile-page'>
        <div className='profile-tour-guide'>
            <div className='profile-view-in-tour-guide'>
            {localStorage.getItem('profilePicture') ? (
            <img src={localStorage.getItem('profilePicture')} style={{height:'100%', width:'100%', objectFit:'cover'}} alt="Profile" className="profilePicture" />
            ) :(
            <svg xmlns="http://www.w3.org/2000/svg"  height="100" fill="white" className="bi bi-person-fill" viewBox="0 -2 17 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
            </svg>
            )}
            </div>
            {/* color:'rgb(202, 53, 190) */}
            <div className='text-section'>
                <h3 style={{color:'black', marginBottom:'-10px', fontSize:'24px', marginTop:'15px'}}>{localStorage.getItem('nameOfTheTourist')?`${localStorage.getItem('nameOfTheTourist')}`:`User`}</h3>
                <p style={{color:'black', marginBottom:'-10px', fontSize:'14px'}}>{localStorage.getItem('status')?`${localStorage.getItem('status')}`:'N/A'}</p>
                <p style={{color:'black', marginBottom:'-10px', fontSize:'14px'}}>Average Rating : <span className='rating-stars'>{renderStars(averageRating)}</span></p>
            </div>
            <div className='total-trips'>
                Number of Trips: 0
            </div>
        </div>
        <div className='left-margin' style={{marginTop: '40px', display:'flex', alignItems:'center', gap:'10px'}}>
            <div style={{border:'1px solid grey', backgroundColor:'grey', width:'5%', height:'0px'}}></div>
            <h3 style={{color:'grey'}}>Reviews</h3>
            <div style={{border:'1px solid grey', backgroundColor:'silver', width:'70%', height:'0px'}}></div>
        </div>
        <div id='reviews-box'>
        {reviews.map((review, index) => (
            <div key={index} className="review-card">
                <div style={{display:'flex',alignItems:'center', gap:'20px'}}>
                <div style={{height:'50px', width:'50px'}} className='profile-view-in-tour-guide'>
                    {review.profilePicture ? (
                    <img src={review.profilePicture} style={{height:'100%', width:'100%', objectFit:'cover'}} alt="Profile" className="profilePicture" />
                    ) :(
                    <svg xmlns="http://www.w3.org/2000/svg"  height="50" fill="white" className="bi bi-person-fill" viewBox="0 -2 17 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                    </svg>
                    )}
                </div>
                    <h3 style={{color:'rgb(202, 53, 190)'}}>{review.name}</h3>
                </div>
                <p>{review.review}</p>
                <p className="rating">Rating: <span className='rating-stars'>{renderStars(review.rating)}</span></p>
            </div>
        ))}
        </div>
        {reviews.length === 0 && (<div style={{display:'flex',alignItems:'center',justifyContent:"center", width:'83%'}}>
                <p>No reviews yet</p>
        </div>)}
      </div>
    </section>
  )
}

export default TourGuideReview
