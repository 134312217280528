import React,{useState} from 'react'
import Dashboard from './Components/dashboard'
import './TourGuideProfile.css'
import TourGuideEditProfile from './Components/TourGuideEditProfile';
import TourGuideReview from './Components/TourGuideReview';
import TourGuideEarnings from './TourGuideEarnings';
import TourPlans from './Components/TourPlans';

function TourGuideProfile() {
  const [activeOption,setActiveOption]=useState('editProfile');
  const [isdashboardOpen,dashboardOpener]=useState(false);
  return (
    <section id='tour-guide-profile'>
      <Dashboard activeOption={activeOption} setActiveOption={setActiveOption} isdashboardOpen={isdashboardOpen} dashboardOpener={()=>dashboardOpener(!isdashboardOpen)}/>
      {activeOption==='editProfile' && (
        <TourGuideEditProfile  dashboardOpener={()=>dashboardOpener(!isdashboardOpen)}/>
      )}
      {activeOption==='reviews' && (
        <TourGuideReview  dashboardOpener={()=>dashboardOpener(!isdashboardOpen)}/>
      )}
      {activeOption==='earnings' && (
        <TourGuideEarnings  dashboardOpener={()=>dashboardOpener(!isdashboardOpen)}/>
      )}
      {activeOption==='tourPlans' && (
        <TourPlans dashboardOpener={()=>dashboardOpener(!isdashboardOpen)}/>
      )}
    </section>
  )
}

export default TourGuideProfile
