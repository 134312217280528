//C:\Users\Lenovo\Desktop\ExploreXpert\my-react-app

import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import ErrorMessage from './HomePage/ErrorMessage';
import Select from 'react-select';
import 'react-phone-input-2/lib/style.css';
import './userProfile.css';
import axios from 'axios';

function UserProfile({setUserProfileVisible}) {

    const languages = [
        { name: "Albanian", country: "Albania" },
        { name: "Arabic", country: "Arab World" },
        { name: "Armenian", country: "Armenia" },
        { name: "Awadhi", country: "India" },
        { name: "Azerbaijani", country: "Azerbaijan" },
        { name: "Bashkir", country: "Russia" },
        { name: "Basque", country: "Spain" },
        { name: "Belarusian", country: "Belarus" },
        { name: "Bengali", country: "Bangladesh" },
        { name: "Bhojpuri", country: "India" },
        { name: "Bosnian", country: "Bosnia and Herzegovina" },
        { name: "Brazilian Portuguese", country: "Brazil" },
        { name: "Bulgarian", country: "Bulgaria" },
        { name: "Cantonese (Yue)", country: "China" },
        { name: "Catalan", country: "Spain" },
        { name: "Chhattisgarhi", country: "India" },
        { name: "Chinese", country: "China" },
        { name: "Croatian", country: "Croatia" },
        { name: "Czech", country: "Czech Republic" },
        { name: "Danish", country: "Denmark" },
        { name: "Dogri", country: "India" },
        { name: "Dutch", country: "Netherlands" },
        { name: "English", country: "United Kingdom" },
        { name: "Estonian", country: "Estonia" },
        { name: "Faroese", country: "Faroe Islands" },
        { name: "Finnish", country: "Finland" },
        { name: "French", country: "France" },
        { name: "Galician", country: "Spain" },
        { name: "Georgian", country: "Georgia" },
        { name: "German", country: "Germany" },
        { name: "Greek", country: "Greece" },
        { name: "Gujarati", country: "India" },
        { name: "Haryanvi", country: "India" },
        { name: "Hindi", country: "India" },
        { name: "Hungarian", country: "Hungary" },
        { name: "Indonesian", country: "Indonesia" },
        { name: "Irish", country: "Ireland" },
        { name: "Italian", country: "Italy" },
        { name: "Japanese", country: "Japan" },
        { name: "Javanese", country: "Indonesia" },
        { name: "Kannada", country: "India" },
        { name: "Kashmiri", country: "India" },
        { name: "Kazakh", country: "Kazakhstan" },
        { name: "Konkani", country: "India" },
        { name: "Korean", country: "South Korea" },
        { name: "Kyrgyz", country: "Kyrgyzstan" },
        { name: "Latvian", country: "Latvia" },
        { name: "Lithuanian", country: "Lithuania" },
        { name: "Macedonian", country: "North Macedonia" },
        { name: "Maithili", country: "India" },
        { name: "Malay", country: "Malaysia" },
        { name: "Maltese", country: "Malta" },
        { name: "Mandarin", country: "China" },
        { name: "Mandarin Chinese", country: "China" },
        { name: "Marathi", country: "India" },
        { name: "Marwari", country: "India" },
        { name: "Min Nan", country: "China" },
        { name: "Moldovan", country: "Moldova" },
        { name: "Mongolian", country: "Mongolia" },
        { name: "Montenegrin", country: "Montenegro" },
        { name: "Nepali", country: "Nepal" },
        { name: "Norwegian", country: "Norway" },
        { name: "Oriya", country: "India" },
        { name: "Pashto", country: "Afghanistan" },
        { name: "Persian (Farsi)", country: "Iran" },
        { name: "Polish", country: "Poland" },
        { name: "Portuguese", country: "Portugal" },
        { name: "Punjabi", country: "India" },
        { name: "Rajasthani", country: "India" },
        { name: "Romanian", country: "Romania" },
        { name: "Russian", country: "Russia" },
        { name: "Sanskrit", country: "India" },
        { name: "Santali", country: "India" },
        { name: "Serbian", country: "Serbia" },
        { name: "Sindhi", country: "Pakistan" },
        { name: "Sinhala", country: "Sri Lanka" },
        { name: "Slovak", country: "Slovakia" },
        { name: "Slovene", country: "Slovenia" },
        { name: "Slovenian", country: "Slovenia" },
        { name: "Ukrainian", country: "Ukraine" },
        { name: "Urdu", country: "Pakistan" },
        { name: "Uzbek", country: "Uzbekistan" },
        { name: "Vietnamese", country: "Vietnam" },
        { name: "Welsh", country: "Wales" },
        { name: "Wu", country: "China" },
      ];

    const [activeUserOption,setActiveUserOption]=useState('yourProfile');
    const [userFullName,setUserFullName]=useState('');
    const [legalName,setLegalName]=useState('');
    const [userPhoneNumber,setUserPhoneNumber]=useState('');
    const [userAddress,setUserAddress]=useState('');
    const [userComfortableLanguages,setUserConfortableLanguages]=useState('English');
    const [errorInFullName,setErrorInFullName]=useState('');
    const [errorInPhoneNumber,setErrorInPhoneNumber]=useState('');
    const [errorInAddress,setErrorInAddress]=useState('');
    const [errorInLanguages,setErrorInLanguages]=useState('');
    const [errorInPreferredCurrency,setErrorInPreferredCurrency]=useState('');
    const [file, setIdentityDocument] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [documentName, setDocumentName] = useState('');
    const [isMobileNumberVerified,setIsMobileNumberVerified]=useState(false);
    const [optionEnabledForUserProfile,setOptionEnabledForUserProfile]=useState('');
    const [userSubscription,setUserSubscription]=useState('Free Plan - 0$/month');
    const [preferredCurrency,setPreferredCurrency]=useState('');
    const [OTP_send_to_ph,setOTP_send_to_ph]=useState('');
    const [enteredOTP,setEnteredOTP]=useState('');
    const [errorInOTP,setErrorInOTP]=useState('');
    const [subscribedPlanDisables,setSubscribedPlanDisabled]=useState('Free Plan - 0$/month');
    const [latestTransactions,setLatestTransactions]=useState('Not Available');
    const [couponCode, setCouponCode]=useState('');
    const [errorInCoupounCode,setErrorInCoupounCode]=useState('');

    const [confirmUserFullName,setConfirmUserFullName]=useState('');
    const [confirmPhoneNumber,setConfirmUserPhoneNumber]=useState('');
    const [confirmAddress,setConfirmAddress]=useState('');
    const [confirmLanguages,setConfirmLanguages]=useState('');
    const [confirmPrefferedCurrency,setConfirmPreferredCurrency]=useState('');
    const [verifyButtonDisabled, setVerifyButtonDisables]=useState(true);
    const [documentVerificationStatus, setDocumentVerificationStatus]=useState('');
    const [verifiedDocumentName,setVerifiedDocumentName]=useState('');
    const [confirmCoupounCode,setConfirmCoupounCode]=useState('');

    const [priceForFortNightPlan,setPriceForFortNightPlan]=useState(11.99);
    const [priceForQuadweekPlan,setPriceForQuadweekPlan]=useState(19.99);

    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    const [transactions,setTransactions]=useState([]);
    const [moneyEarned,setMoneyEarned]=useState([]);
    const [totalMoneyEarned,setTotalMoneyEarned]=useState(0);
    const [error,setError]=useState(null);

    const [tourGuidePrice,setTourGuiidePrice]=useState(0);
    const [confirmTourGuidePrice,setConfirmTourGuidePrice]=useState(0);


  const handleChangeLanguage = (event) => {
    const inputValue = event.target.value;
    // const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    // const uppercaseValue = filteredValue.toUpperCase();
    setUserConfortableLanguages(inputValue);
  };


//   const handleLanguageChange = (e) => {
//     const inputValue = e.target.value;
//     const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
//     const uppercaseValue = filteredValue.toUpperCase();
//     setUserConfortableLanguages(uppercaseValue);
// };


    const screenWidth = window.innerWidth;

    useEffect(() => {
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
        const storedIsLoggedInByGoogle =localStorage.getItem('isLoggedInByGoogle');
        if(storedIsLoggedIn || storedIsLoggedInByGoogle){
            
            const storedLoggedInEmail =(storedIsLoggedIn)?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');

            console.log(storedLoggedInEmail);
            
            const loadData=async ()=>{
                try{
                    const response = await fetch('/api/loadUserData', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({storedLoggedInEmail})
                    });
                    const data = await response.json();
                    if(data.username!=null){
                        setUserFullName(data.username);
                        setConfirmUserFullName(data.username);
                        
                    }
                    if(data.phoneNumber!=null){
                        setUserPhoneNumber(`+${data.phoneNumber}`);
                        setConfirmUserPhoneNumber(`+${data.phoneNumber}`);
                        setIsMobileNumberVerified(true);
                    }
                    if(data.address!=null){
                        setUserAddress(data.address);
                        setConfirmAddress(data.address);
                    }
                    if(data.profilePicture!=null){
                        const file = `/uploads/${data.profilePicture}`;
                       setProfilePicture(file);
                    }
                    if(data.preferredCurrency!=null){
                        setPreferredCurrency(data.preferredCurrency);
                        setConfirmPreferredCurrency(data.preferredCurrency);
                        localStorage.setItem("preferredCurrency",data.preferredCurrency);
                    }
                }catch (error) {
                }

                try{
                    const response = await fetch('/api/tourOperatorData', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({storedLoggedInEmail})
                    });
                    const data = await response.json();
                    if(data.legalname!=null){
                        setLegalName(data.legalname);
                    }
                    if(data.price!=null){
                        const priceAsString = String(data.price);
                        setTourGuiidePrice(priceAsString);
                        setConfirmTourGuidePrice(priceAsString);
                    }
                    if(data.phoneNumber!=null){
                        setUserPhoneNumber(`+${data.phoneNumber}`);
                        setConfirmUserPhoneNumber(`+${data.phoneNumber}`);
                        setIsMobileNumberVerified(true);
                    }
                    if(data.address!=null){
                        setUserAddress(data.address);
                        setConfirmAddress(data.address);
                    }
                    if(data.legalDocument!=null){
                        const fullDocumentName = data.legalDocument;
                        const delimiter = '_';
                        const documentName = fullDocumentName.split(delimiter).slice(1).join(delimiter);
                        setDocumentName(documentName);
                        setVerifiedDocumentName(fullDocumentName);
                    }
                    
                    if(data.verification!=='not verified'){
                        setDocumentVerificationStatus(data.verification);
                    }
                }catch (error) {
                }
                try {
                    const response = await axios.post('/api/getTransactionHistory', { storedLoggedInEmail: storedLoggedInEmail });
                    const transactionData = response.data;
                    setTransactions(transactionData);
                    if (transactionData.length > 0) {
                        const sortedTransactions = [...transactionData].sort((a, b) => new Date(b.time) - new Date(a.time));
                        const latestTransaction = sortedTransactions[0];
                        setLatestTransactions(`${latestTransaction.amount}`);
                    } else {
                        setLatestTransactions('Not Available');
                    }
                } catch (error) {
                }
                try {
                    const response = await axios.post('/api/getEarnedMoney', { storedLoggedInEmail: storedLoggedInEmail });
                    const transactionData = response.data;
                    setMoneyEarned(transactionData);
                    if (transactionData.length > 0) {
                        const totalAmount = transactionData.reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);
                        setTotalMoneyEarned(totalAmount);
                    } else {
                        setTotalMoneyEarned(0);
                    }
                } catch (error) {
                }
            }
            loadData();
        }
      }, []);


    useEffect(() => {
        const fetchCurrencies = async () => {
          try {
            const response = await axios.get('/api/currencies');
            const options = Object.keys(response.data).map((key) => ({
              value: key,
              label: `${key} - ${response.data[key]}`,
            }));
            setCurrencies(options);
          } catch (error) {
            console.error('Error fetching currencies:', error);
          }
        };
    
        fetchCurrencies();
    }, []);


      const handleChange = (selectedOption) => {
        setSelectedCurrency(selectedOption);
      };


      useEffect(()=>{
        if(sessionStorage.getItem('discounted_QuadweekPrice')){
            setPriceForQuadweekPlan(sessionStorage.getItem('discounted_QuadweekPrice'));
            setPriceForFortNightPlan(sessionStorage.getItem('discounted_Forthightplan'));
        }
    },[priceForFortNightPlan,priceForQuadweekPlan]);

    
    const activeUserOptions=(selectedOption)=>{
        setActiveUserOption(selectedOption);
        if(selectedOption==="globalPreferences"){
            handleChangeOptionEnabledForUser('subscribe');
        }
    }

    const handleChangeOptionEnabledForUser=(userOption)=>{
        if(optionEnabledForUserProfile===userOption){
            setOptionEnabledForUserProfile('');
        }else{
            setOptionEnabledForUserProfile(userOption);
        }
    }

    const saveToDataBaseUserFullName= async ()=>{
        const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');

        if(userFullName.length <= 0){
            setUserFullName(confirmUserFullName);
        }
        else {
            setConfirmUserFullName(userFullName);
            try{
                const response = await fetch('/api/userFullName', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({email,userFullName})
                });
                const data = await response.json();
                if(data.message!=='userFullName reset successfully'){
                    setErrorInFullName(data.message);
                }
            }catch (error) {
            }
        }
        setOptionEnabledForUserProfile('');
    }

    // const saveToDataBaseUserLegalName=async ()=>{
    //     const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    //     const regex = /[^a-zA-Z\s]/;
    //     if(legalName.length <= 0){
    //         setLegalName(confirmLegalName);
    //         setErrorInLegalName("can't be empty");
    //     }
    //     else if(regex.test(legalName)){
    //         setErrorInLegalName("The legal name contains numbers or symbols")
    //     }
    //     else {
    //         setConfirmLegalName(legalName);
    //         try{
    //             const response = await fetch('/api/legalName', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({email,legalName})
    //             });
    //             const data = await response.json();
    //             if(data.message!=='legal Name reset successfully'){
    //                 setErrorInFullName(data.message);
    //             }
    //         }catch (error) {
    //         }
    //         setOptionEnabledForUserProfile('');
    //     }
    // }

    const saveToDataBaseTouristPrice=async ()=>{
        const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
        if(tourGuidePrice.length <= 0){
            setTourGuiidePrice(confirmTourGuidePrice);
        }
        else {
            setConfirmTourGuidePrice(tourGuidePrice);
            const formattedPrice = parseFloat(tourGuidePrice).toFixed(2);
            try{
                const response = await fetch('/api/tourguidePrice', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({email,formattedPrice})
                });
                const data = await response.json();
            }catch (error) {
                alert('something went wrong. please try later.');
            }
            setOptionEnabledForUserProfile('');
        }
    }

    // const saveToDataBaseUserPhoneNumber=async()=>{
    //     if(OTP_send_to_ph===enteredOTP){
    //         const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    //         setConfirmUserPhoneNumber(userPhoneNumber);
    //         try{
    //             const response = await fetch('/api/phoneNumber', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({email,userPhoneNumber})
    //             });
    //             const data = await response.json();
    //             if(data.message!=='Phone Number reset successfully'){
    //                 setErrorInOTP(data.message);
    //             }else{
    //                 setOptionEnabledForUserProfile('');
    //             }
    //         }catch (error) {
    //         }
            
    //     }else{
    //         setErrorInOTP('wrong OTP');
    //     }
    // }

    // const saveToDataBaseUserAddress=async()=>{
    //     const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');

    //     if(userAddress.length <= 0){
    //         setUserAddress(confirmAddress);
    //     }
    //     else {
    //         setConfirmAddress(userAddress);
    //         try{
    //             const response = await fetch('/api/address', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({email,userAddress})
    //             });
    //             const data = await response.json();
    //             if(data.message!=='address reset successfully'){
    //                 setErrorInAddress(data.message);
    //             }
    //         }catch (error) {
    //         }
    //     }
    //     setOptionEnabledForUserProfile('');
    // }
    
    // const saveToDataBaseUserComfortableLanguages=async()=>{
    //     const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');

    //     if(userComfortableLanguages.length <= 0){
    //         setUserConfortableLanguages(confirmLanguages);
    //     }
    //     else {
    //         setConfirmLanguages(userComfortableLanguages);
    //         try{
    //             const response = await fetch('/api/confortableLanguages', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({email,userComfortableLanguages})
    //             });
    //             const data = await response.json();
    //             if(data.message!=='languages reset successfully'){
    //                 setErrorInLanguages(data.message);
    //             }else{
    //                 localStorage.setItem("languageDetail",userComfortableLanguages);
    //             }
    //         }catch (error) {
    //         }
    //     }
    //     setOptionEnabledForUserProfile('');
    // }

    // const saveToDataBaseUserPrefferedCurrency=async()=>{
    //     const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');

    //    const preferredCurrency = selectedCurrency ? selectedCurrency.value : '';

    //     if (!preferredCurrency) {
    //         setPreferredCurrency(confirmPrefferedCurrency);
    //     } else {
    //         setConfirmPreferredCurrency(preferredCurrency);
    //         try{
    //             const response = await fetch('/api/preferredCurrency', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({email,preferredCurrency})
    //             });
    //             const data = await response.json();
    //             if(data.message!=='preferred currency reset successfully'){
    //                 setErrorInPreferredCurrency('preferredCurrency',preferredCurrency);
    //             }else{
    //                 localStorage.setItem("preferredCurrency",preferredCurrency);
    //             }
    //         }catch (error) {
    //             console.error('Error saving usrrname:', error);
    //         }
    //     }
    //     setOptionEnabledForUserProfile('');
    // }

    // const handlePayment = async (plan) => {
    //     try {
    //       let amount=10;
    //       const preferredCurrency = localStorage.getItem("preferredCurrency") || 'USD';
    //       if (plan === 'Fortnight Plan - 12.99$/2-weeks') {
    //         const amountInUSD = priceForFortNightPlan;
    //         if(preferredCurrency!=='USD'){
    //             const response = await axios.post('/api/processPayment', {
    //                 amountInUSD,
    //                 userPreferredCurrency: preferredCurrency,
    //             });
    //             amount=parseFloat(response.data.amountInPreferredCurrency.toFixed(2)); ; 
    //         }
    //         else{
    //             amount=amountInUSD;
    //         }
    //       } else if (plan === 'Quadweek Plan - 20.99$/28-days') {
    //         const amountInUSD = priceForQuadweekPlan;
    //         if(preferredCurrency!=='USD'){
    //             const response = await axios.post('/api/processPayment', {
    //                 amountInUSD,
    //                 userPreferredCurrency: preferredCurrency,
    //             });
    //             amount=parseFloat(response.data.amountInPreferredCurrency.toFixed(2)); ; 
    //         }
    //         else{
    //             amount=amountInUSD;
    //         }
    //       } else {
    //         purchaseSubscription(plan);
    //         return;
    //       }
    //       const order = await axios.post('/api/create-order', { amount,preferredCurrency });
    //       const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    //       const touristMail = storedIsLoggedIn? localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    //       const options = {
    //         key: 'rzp_live_DqIp2pXc46hk0k',
    //         amount: order.data.amount,
    //         currency: preferredCurrency,
    //         name: 'ADVENTROZ',
    //         description: 'Test Transaction',
    //         image: 'https://adventroz.com/favicon.ico',
    //         order_id: order.data.id,
    //         handler: async (response) => {
    //           const data = {
    //             razorpay_order_id: response.razorpay_order_id,
    //             razorpay_payment_id: response.razorpay_payment_id,
    //             razorpay_signature: response.razorpay_signature,
    //           };
      
    //           try {
    //             const result = await axios.post('/api/verify-signature', data);
    //             if (result.data.status === 'success') {
    //               alert('Payment successful');
    //               purchaseSubscription(plan);
    //             } else {
    //               alert('Payment verification failed');
    //             }
    //           } catch (error) {
    //             alert('Signature verification failed');
    //           }
    //         },
    //         prefill: {
    //           email: touristMail,
    //         },
    //         theme: {
    //           color: '#F37254',
    //         },
    //       };
      
    //       const rzp = new window.Razorpay(options);
    //       rzp.open();
    //     } catch (error) {
    //       alert('There was an error processing your payment. Please try again later.');
    //     }
    //   };

    // const purchaseSubscription=async(plan)=>{
    //     if(plan==='Free Plan - 0$/month'){
    //         const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    //         const amount=0;
    //         try{
    //             const response = await fetch('/api/purchaseSubscription', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({email,plan,amount})
    //             });
    //             const data = await response.json();
    //             if(data.message!=='preferred currency reset successfully'){
                    
    //             }else{
    //                 setUserSubscription(plan);
    //                 setSubscribedPlanDisabled(plan);
    //                 localStorage.setItem("subscriptionDetail",plan);
    //             }
    //         }catch (error) {
    //         }
    //         setOptionEnabledForUserProfile('');
    //     }
    //     else if(plan==='Fortnight Plan - 12.99$/2-weeks'){
    //         const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    //         const amount=11.99;
    //         try{
    //             const response = await fetch('/api/purchaseSubscription', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({email,plan,amount})
    //             });
    //             const data = await response.json();
    //             if(data.message!=='preferred currency reset successfully'){
                    
    //             }else{
    //                 setUserSubscription(plan);
    //                 setSubscribedPlanDisabled(plan);
    //                 localStorage.setItem("subscriptionDetail",plan);
    //             }
    //         }catch (error) {
    //             // ('Error saving usrrname:', error);
    //         }
    //        setOptionEnabledForUserProfile('');
    //      }
    //      else if(plan==='Quadweek Plan - 20.99$/28-days'){
    //         const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    //         const amount=19.99;
    //         try{
    //             const response = await fetch('/api/purchaseSubscription', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({email,plan,amount})
    //             });
    //             const data = await response.json();
    //             if(data.message!=='preferred currency reset successfully'){
                    
    //             }else{
    //                 setUserSubscription(plan);
    //                 setSubscribedPlanDisabled(plan);
    //                 localStorage.setItem("subscriptionDetail",plan);
    //             }
    //         }catch (error) {
    //             // ('Error saving usrrname:', error);
    //         }
    //         setOptionEnabledForUserProfile('');
    //  }
    // }
    
    // const checkAndEnableCoupounCoode=async()=>{
    //     if(couponCode===`gangu_05`){
    //         setPriceForQuadweekPlan(`18.99`);
    //         setPriceForFortNightPlan(`11.39`);
    //         sessionStorage.setItem('discounted_QuadweekPrice',18.99);
    //         sessionStorage.setItem(`discounted_Forthightplan`,11.39);
    //         setConfirmCoupounCode(couponCode);
    //         setOptionEnabledForUserProfile('');
    //     }else{
    //         setErrorInCoupounCode("Wrong Coupon code");
    //     }
    // }

    // const saveProfilePictureToDtaBase=async (file)=>{
    //     const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    //     console.log("function called");
    //     try{
    //         console.log(file);
    //         const formData = new FormData();
    //         formData.append('file', file);
    //         formData.append('email', email);

    //         const result=await axios.post('/api/userprofilePicture',
    //             formData,
    //             {
    //                 headers:{"Content-Type":"multipart/form-data"}
    //             }
    //         )
    //         console.log(result.data);
    //         if(result.data==='legal document reset successfully'){
                
    //         }
    //         setVerifyButtonDisables(true);
    //     }catch (error) {
    //     }
    // }

    // const handleProfilePictureChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             setProfilePicture(e.target.result);
    //         };
    //         reader.readAsDataURL(file);
    //         saveProfilePictureToDtaBase(file);
    //     }
    // };

    // const handleFileChange = (e) => {
    //     if (e.target.files[0].type === "application/pdf") {
    //       setIdentityDocument(e.target.files[0]);
    //       setDocumentName(e.target.files[0].name);
    //       setVerifyButtonDisables(false);
    //     } else {
    //       alert("Please upload a valid PDF document.");
    //     }
    // };

    // const sendPdfFileForVerification=async()=>{
    //     const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    //     if (!file) {
    //         return;
    //     }
    //     if(verifyButtonDisabled){
    //         return;
    //     }
    //     try{
    //         console.log(file);
    //         const formData = new FormData();
    //         formData.append('file', file);
    //         formData.append('email', email);

    //         const result=await axios.post('/api/legalDocument',
    //             formData,
    //             {
    //                 headers:{"Content-Type":"multipart/form-data"}
    //             }
    //         )
    //         console.log(result.data);
    //         if(result.data==='legal document reset successfully'){
                
    //         }
    //         setVerifyButtonDisables(true);
    //         setDocumentVerificationStatus("veryfying...")
    //     }catch (error) {
    //         setErrorInLegalDocument(error);
    //     }
    // }
    
    const sendOtpToPhoneNumber=async()=>{
        if(userPhoneNumber.length!==12){
            setErrorInPhoneNumber("phone number must have 10 numbers");
            setConfirmUserPhoneNumber(userPhoneNumber);
        }else{
            try{
                const response = await fetch('/api/verifyPhoneNumber', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({userPhoneNumber})
                });
                const data = await response.json();
                if(data.message==='otp send successfully'){
                    setOTP_send_to_ph(data.otp);
                }
            }catch (error) {
            }
        }
    }

    const showVerifiedDocument=()=>{
        if(documentName==='' && !(documentVerificationStatus==='verifying...' || documentVerificationStatus==='verified')){
            return;
        }
        window.open(`/uploads/${verifiedDocumentName}`);
    }

    const handleDivClick = () => {
        const fileInput = document.getElementById('profilePictureInput');
        if (fileInput) {
            fileInput.click();
        }
    };

    

    const handleCloseError = () => {
        setError(null);
    };

    const customStyles = {
        container: (provided) => ({
          ...provided,
          display: optionEnabledForUserProfile === 'preferredCurrency' ? 'block' : 'none',
          width: screenWidth<940?screenWidth<376?'60%':'70%':'75%',
          fontSize:'12px',
          height:screenWidth<376?'35px':''
        }),
        control: (provided) => ({
          ...provided,
          width: '100%',
        }),
        menu: (provided) => ({
          ...provided,
          maxHeight: 140,
          overflowY: 'auto',
        }),
      };

      

    
    return (
        <div className='userPoriflePage'>
            <div className='userProfile'>
                
                {activeUserOption==='yourProfile' &&(
                    <div style={{width:'100%'}} className='asATourist'>
                        <div className="user-profile">
                            <h2>Personal Info</h2>
                            <div style={{height:optionEnabledForUserProfile==='legalName'?'130px':''}} className="info-item">
                                <span className='info-item-row'>
                                    <span className="label-info">Username</span>
                                    <span style={{display:optionEnabledForUserProfile==='legalName'? 'none':'block'}} className="value-info">{userFullName===''?'Not provided':userFullName}</span>
                                    <div style={{display:optionEnabledForUserProfile==='legalName'? 'block':'none'}} className='phoneNumber'>
                                        <input style={{display:optionEnabledForUserProfile==='legalName'? 'block':'none'}} type="text" value={userFullName} onChange={(e)=>setUserFullName(e.target.value)} onPaste={(e)=>setUserFullName(e.clipboardData.getData('text'))} id="fullname" name="fullname" placeholder="Enter Your Fullname : "/>
                                        <button type='button' onClick={()=>{saveToDataBaseUserFullName()}}>Save</button>
                                        <p className='errorInPassword'>{errorInFullName}</p>
                                    </div>
                                </span>
                                <button style={{ marginTop:optionEnabledForUserProfile==='legalName'?'-100px':'-20px'}} className="edit-button" onClick={() => {handleChangeOptionEnabledForUser('legalName')}}>{optionEnabledForUserProfile==='legalName'?'Cancel':(userFullName===''?'Add':'Edit')}</button>
                            </div>
                            <div style={{height:optionEnabledForUserProfile==='phoneNumber'?'130px':''}} className="info-item">
                                <span className='info-item-row'>
                                    <span className="label-info">Phone Number</span>
                                    <span style={{display:optionEnabledForUserProfile==='userphoneNumber'? 'none':'block'}} className="value-info">{confirmPhoneNumber===''?'Not provided':confirmPhoneNumber}</span>
                                    <span style={{display:optionEnabledForUserProfile==='userphoneNumber'? 'block':'none'}} className="value-info">Enter your Phone Number to link account.</span>
                                    <div style={{display:(optionEnabledForUserProfile==='userphoneNumber' && OTP_send_to_ph==='')? 'block':'none'}} className='phoneNumber'>
                                        <PhoneInput inputStyle={{width:(screenWidth<910)?(screenWidth<400)?(screenWidth<330)?'130px':'150px':'200px':'330px',fontSize:(screenWidth<400)?(screenWidth<330)?'10px':'12px':'15px', backgroundColor:' rgb(244, 243, 243)',}} country={'in'} value={userPhoneNumber} onChange={(phone) => setUserPhoneNumber(phone)} onPaste={(e)=>setUserPhoneNumber(e.clipboardData.getData('text'))} inputProps={{ name: 'phone',required: true,autoFocus: true}}/>
                                        <p className='errorInPassword'>{errorInPhoneNumber}</p>
                                    </div>
                                    <button style={{display:(optionEnabledForUserProfile==='userphoneNumber' && OTP_send_to_ph==='')? 'block':'none', marginTop:'-60px',marginLeft:(screenWidth<910)?(screenWidth<400)?(screenWidth<330)?'130px':'150px':'210px':'330px', padding:(screenWidth<330)?'5px':'', fontSize:(screenWidth<330)?'10px':'12px', marginBottom:'20px', backgroundColor:(isMobileNumberVerified && confirmPhoneNumber===userPhoneNumber)?'grey':'black'}} disabled={(isMobileNumberVerified && confirmPhoneNumber===userPhoneNumber)} type='button' className='phone-Number-verification' onClick={sendOtpToPhoneNumber}>{(isMobileNumberVerified && confirmPhoneNumber===userPhoneNumber)?'verified':'verify'}</button>
                                    <div style={{display:(optionEnabledForUserProfile==='userphoneNumber' && OTP_send_to_ph!=='')? 'block':'none'}} className='phoneNumber'>
                                        <input type="password" value={enteredOTP} onChange={(e)=>setEnteredOTP(e.target.value)} onPaste={(e)=>setEnteredOTP(e.clipboardData.getData('text'))} id="password" name="password" placeholder="Enter OTP :" required/>
                                        <button style={{marginTop:'3px'}} type='button' onClick={()=>{}}>Save</button>
                                        <p className='errorInPassword'>{errorInOTP}</p>
                                    </div>    
                                </span>
                                <button style={{ marginTop:optionEnabledForUserProfile==='userphoneNumber'?'-100px':'-20px'}} className="edit-button" onClick={() => {handleChangeOptionEnabledForUser('userphoneNumber')}}>{optionEnabledForUserProfile==='userphoneNumber'?'Cancel':(userPhoneNumber===''?'Add':'Edit')}</button>
                            </div>
                            <div style={{height:optionEnabledForUserProfile==='transaction-history'?'270px':''}} className="info-item">
                                <span className='info-item-row'>
                                    <span className="label-info">transaction History</span>
                                    <span style={{display:optionEnabledForUserProfile==='transaction-history'? 'none':'block'}} className="value-info">{(latestTransactions==="Not Available")?latestTransactions:"Latest Transaction : "+latestTransactions+'$'}</span>
                                    <span style={{display:optionEnabledForUserProfile==='transaction-history'? 'block':'none'}} className="value-info">Latest five transactions are below.</span>
                                    <div style={{display:optionEnabledForUserProfile==='transaction-history'? 'block':'none'}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="th th-td">Description</th>
                                                    <th className="th th-td">Amount (in $)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactions.length === 0 ? (
                                                    <tr>
                                                        <td className="no-results" colSpan="3">No results found</td>
                                                    </tr>
                                                ) : (
                                                    transactions.map((transaction, index) => (
                                                        <tr key={index}>
                                                            <td className="th-td">{transaction.description}</td>
                                                            <td className="th-td">{transaction.amount}</td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                                <button style={{ marginTop:optionEnabledForUserProfile==='transaction-history'?'-240px':'-20px' ,marginLeft:optionEnabledForUserProfile==='transaction-history'?'-100px':''}} className="edit-button" onClick={() => {handleChangeOptionEnabledForUser('transaction-history')}}>{optionEnabledForUserProfile==='transaction-history'?'hide':'view'}</button>
                            </div>
                            {/* <div style={{height:optionEnabledForUserProfile==='languages'?'130px':''}} className="info-item">
                                <span className='info-item-row'>
                                    <span className="label-info">Languages</span>
                                    <span style={{display:optionEnabledForUserProfile==='languages'? 'none':'block'}} className="value-info">{confirmLanguages===''?'Not provided':confirmLanguages}</span>
                                    <span style={{display:optionEnabledForUserProfile==='languages'? 'block':'none'}} className="value-info">Enter the languages that you are comfortable with </span>
                                    <div style={{display:optionEnabledForUserProfile==='languages'? 'block':'none', width:'100%'}} className='phoneNumber'>
                                        <select id="language" value={userComfortableLanguages} onChange={handleChangeLanguage}
                                            style={{
                                                padding: '8px',
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                fontSize: screenWidth>517?'16px':'10px',
                                                width: screenWidth>517?'250px':'63%',
                                                boxSizing: 'border-box',
                                              }}
                                        
                                        >
                                            {languages.map((language, index) => (
                                            <option key={index} value={language.name}>
                                                {language.name} - {language.country}
                                            </option>
                                            ))}
                                        </select>
                                        <button style={{marginTop:'0px', marginLeft:'-10px',fontSize:'10px',height:'30px'}} onClick={()=>{}}>Save</button>
                                        <p className='errorInPassword'>{errorInLanguages}</p>
                                    </div>
                                </span>
                                <button style={{ marginTop:optionEnabledForUserProfile==='languages'?'-100px':'-20px'}} className="edit-button" onClick={() => {handleChangeOptionEnabledForUser('languages')}}>{optionEnabledForUserProfile==='languages'?'Cancel':(userComfortableLanguages===''?'Add':'Edit')}</button>
                            </div> */}
                        </div>
                    </div>   
                )}
                {/* {activeUserOption==='globalPreferences' && (
                    <div className='asATourist'>
                        <div className="user-profile">
                            {screenWidth<695 && (
                                <div className='mobile-view-buttons'>
                                <button className={activeUserOption==='yourProfile'?'activeUser':''} onClick={()=>activeUserOptions('yourProfile')}>Personal Info</button>
                                <button className={activeUserOption==='globalPreferences'?'activeUser':''} onClick={()=>activeUserOptions('globalPreferences')}>Tourist</button>
                                <button className={activeUserOption==='becomeATourGuide'?'activeUser':''} onClick={()=>activeUserOptions('becomeATourGuide')}>Tour Guide</button>
                            </div>)}
                            <h2>Become a Tourist</h2>
                            <div style={{height:(optionEnabledForUserProfile==='subscribe' || (optionEnabledForUserProfile===''))?(screenWidth<695)?'200px':'270px':''}} className="info-item">
                                <span className='info-item-row'>
                                        <span className="label-info">Subscription</span>
                                        <span style={{display:(optionEnabledForUserProfile==='subscribe' || (optionEnabledForUserProfile===''))? 'none':'block'}} className="value-info">{userSubscription}</span>
                                        <span style={{display:(optionEnabledForUserProfile==='subscribe' || (optionEnabledForUserProfile===''))? 'block':'none'}} className="value-info">Purchase the subscription that suits you.</span>
                                        <div style={{display:(optionEnabledForUserProfile==='subscribe' || (optionEnabledForUserProfile===''))? 'flex':'none'}} className='plans'>
                                            <div className='plan'>
                                                <h2 className='plan-heading'>Free Plan</h2>
                                                <h2>-0$/month</h2>
                                                <p className='plan-details'>
                                                    <ul style={{marginTop:'-20px', marginLeft:'-10%'}}>
                                                        <li>45sec max audio</li>
                                                        <li>5 times a day</li>
                                                    </ul>
                                                </p>
                                                <button disabled={true} style={{backgroundColor:'grey'}} className='purchase-subscription'>{(subscribedPlanDisables==="Free Plan - 0$/month")?"Plan in Use":"Inactive"}</button>
                                            </div>
                                            <div  className='plan'>
                                            <h2 className='plan-heading'>Fortnight Plan</h2>
                                                <h2>{`-${priceForFortNightPlan}$/2-weeks`}</h2>
                                                <p className='plan-details'>
                                                    <ul style={{marginTop:'-20px', marginLeft:'-20px'}}>
                                                        <li>90 min audio</li>
                                                        <li>2 weeks only</li>
                                                    </ul>
                                                </p>
                                                <button onClick={()=>{handlePayment('Fortnight Plan - 11.99$/2-weeks')}} disabled={subscribedPlanDisables==="Fortnight - 11.99$/2-weeks"} style={{backgroundColor:(subscribedPlanDisables==="Fortnight Plan - 11.99$/2-weeks")?'grey':'black'}} className='purchase-subscription'>{(subscribedPlanDisables==="Fortnight Plan - 11.99$/2-weeks")?"Purchased":"Purchase"}</button>
                                            </div>
                                            <div  className='plan'>
                                            <h2 className='plan-heading'>Quadweek Plan</h2>
                                                    <h2>{`-${priceForQuadweekPlan}$/28-days`}</h2>
                                                <p className='plan-details'>
                                                    <ul style={{marginTop:'-20px', marginLeft:'-20px'}}>
                                                        <li>180 min audio</li>
                                                        <li>4 weeks only</li>
                                                    </ul>
                                                </p>
                                                <button onClick={()=>{handlePayment('Quadweek Plan - 19.99$/28-days')}} disabled={subscribedPlanDisables==="Quadweek - 19.99$/28-days"} style={{backgroundColor:(subscribedPlanDisables==="Quadweek Plan - 19.99$/28-days")?'grey':'black'}} className='purchase-subscription'>{(subscribedPlanDisables==="Quadweek Plan - 19.99$/28-days")?"Purchased":"Purchase"}</button>
                                            </div>
                                        </div>
                                    </span>
                            </div>
                            <div  style={{height:optionEnabledForUserProfile==='CouponCode'?'100px':''}} className="info-item">
                                <span className='info-item-row'>
                                    <span className="label-info">Coupon Code</span>
                                    <span style={{display:optionEnabledForUserProfile==='CouponCode'? 'none':'block'}} className="value-info">{confirmCoupounCode===''?'Not provided':"Active Coupon Code : "+confirmCoupounCode}</span>
                                    <div style={{display:optionEnabledForUserProfile==='CouponCode'? 'block':'none'}} className='phoneNumber'>
                                        <input style={{display:optionEnabledForUserProfile==='CouponCode'? 'block':'none'}} type="text" value={couponCode} onChange={(e)=>setCouponCode(e.target.value)} onPaste={(e)=>setCouponCode(e.clipboardData.getData('text'))} id="address" name="address" placeholder="Enter Coupon Code : "/>
                                        <button  onClick={()=>{checkAndEnableCoupounCoode()}}>Save</button>
                                        <p className='errorInPassword'>{errorInCoupounCode}</p>
                                    </div>
                                </span>
                                <button style={{ marginTop:optionEnabledForUserProfile==='CouponCode'?'-80px':'-20px'}} className="edit-button" onClick={() => {handleChangeOptionEnabledForUser('CouponCode')}}>{optionEnabledForUserProfile==='CouponCode'?'Cancel':(couponCode===''?'Add':'Edit')}</button>
                            </div>
                        </div>
                    </div>
                )} */}
                <div className='closeArea'>
                <div onClick={()=>{setUserProfileVisible(false)}} className='closeUserProfile'>X</div>
                </div>
            </div>
            {error && (<ErrorMessage message={error} onClose={handleCloseError}/>)}
        </div>
    );
}

export default UserProfile;
