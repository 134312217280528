import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {

    const navigate = useNavigate();

    const navigateToTermsAndCondition=(event)=>{
        event.preventDefault();
        navigate({
            pathname: "/terms_and_condition",
        });
      }
    
      const navigateToWebsite=(event)=>{
        event.preventDefault();
        navigate({
            pathname: "/",
        });
      }
      
    return (
        <div className='termsAndServices'>
            <h1>Privacy Policy for ADVENTROZ</h1>
            <h3>1. Introduction</h3>
            <p>
                Welcome to ADVENTROZ , accessible at <span style={{color:'blue'}} onClick={navigateToWebsite}>www.ADVENTROZ.com</span>.
                We are dedicated to safeguarding your personal information and privacy rights. This privacy policy outlines the collection,
                usage, disclosure, and protection of your information when visiting our website or using our services. We urge you to carefully
                review this privacy policy. If you do not consent to the terms herein, please refrain from accessing the site or using our services.
                You can also review our terms and conditions at 
                <span style={{color:'blue'}} onClick={navigateToTermsAndCondition}>www.ADVENTROZ.com/termsAndCondition</span>
            </p>
            <h3>2. Information We Collect</h3>
            <ul>
                <li>
                    <h3>Personal Information:</h3>
                    <ul>
                        <li>
                            Name
                        </li>
                        <li>
                            Email address
                        </li>
                        <li>
                            Contact number
                        </li>
                        <li>
                            Payment information (credit card details, billing address)
                        </li>
                    </ul>
                </li>
                <li>
                    <h3>Booking Information:</h3>
                    <ul>
                        Tour details (dates, destinations, preferences)
                    </ul>
                    <ul>
                        Audiobook preferences and listening history
                    </ul>
                </li>
                <li>
                    <h3>Technical Information:</h3>
                    <ul>
                        <li>
                            IP address
                        </li>
                        <li>
                            Browser type
                        </li>
                        <li>
                            Operating system
                        </li>
                        <li>
                            Usage data (pages visited, time spent on the site, etc.)
                        </li>
                    </ul>
                </li>
            </ul>
            <h3>3. How We Use Your Information</h3>
            <p>We utilize the collected information in the following ways:</p>
            <ul>
                <li>
                    <p><h4>Service Provision:</h4> Facilitating tour guide bookings and access to monuments audiobooks.</p>
                </li>
                <li>
                    <p><h4>Personalization:</h4> Tailoring our services and suggestions to align with your preferences.</p>
                </li>
                <li>
                    <p><h4>Communication:</h4> Sending confirmations, updates, and promotional offers (with your consent).</p>
                </li>
                <li>
                    <p><h4>Improvement:</h4> Analyzing usage patterns to enhance our services and website functionality.</p>
                </li>
                <li>
                    <p><h4>Security:</h4> Protecting our website and services from fraudulent activities and unauthorized access.</p>
                </li>
            </ul>
            <h3>4. Sharing Your Information </h3>
            <p>Your information may be shared with third parties under the following circumstance:</p>
            <ul>
                <li>
                    <p><h4>Service Providers:</h4> With trusted third parties who assist us in operating our website, conducting our business, or servicing you (e.g., tour guides, payment processors).</p>
                </li>
                <li>
                    <p><h4>Legal Requirements:</h4> When mandated by law or to comply with legal processes.</p>
                </li>
                <li>
                    <p><h4>Business Transfers:</h4>  In the event of a merger, acquisition, or sale of all or a portion of our assets.</p>
                </li>
            </ul>
            <h3>5. Data Security</h3>
            <p>We employ various security measures to safeguard your personal information:</p>
            <ul>
                <li>
                    <p><h4>Encryption:</h4> Securing data storage and transmission using encryption technologies.</p>
                </li>
                <li>
                    <p><h4>Access Control:</h4> Limiting access to personal information to authorized personnel only.</p>
                </li>
                <li>
                    <p><h4>Regular Audits:</h4>  Conducting routine security audits and vulnerability assessments.</p>
                </li>
            </ul>
            <h3>6. Your Rights</h3>
            <p>You have certain rights regarding your personal information:</p>
            <ul>
                <li>
                    <p><h4>Access:</h4> Requesting a copy of your held personal information.</p>
                </li>
                <li>
                    <p><h4>Correction:</h4> Requesting correction of inaccurate or incomplete data.</p>
                </li>
                <li>
                    <p><h4>Deletion:</h4>  Requesting deletion of your personal information under certain conditions.</p>
                </li>
                <li>
                    <p><h4>Objection:</h4> Objecting to the processing of your personal information in certain cases.</p>
                </li>
                <li>
                    <p><h4>Data Portability:</h4> Requesting the transfer of your personal data to another service provider.</p>
                </li>
            </ul>
            <h3>7. Cookies and Tracking Technologies
            We utilize cookies and similar tracking technologies to enhance your website experience:</h3>
            <ul>
                <li>
                    <p><h4>Usage:</h4> Remembering your preferences, understanding site usage, and improving our services.</p>
                </li>
                <li>
                    <p><h4>*Opt-Out:</h4> Configuring your browser to refuse cookies or alert you when cookies are being sent.</p>
                </li>
            </ul>
            <h3>
            8. Changes to This Privacy Policy</h3>
            <p>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.
            </p>
            <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at:
            </p>
            <h3> 9. Contact Us</h3>
            <ul>
                <li>
                    <p><h4>Email:</h4> contact@adventroz.com</p>
                </li>
                <li>
                    <p><h4>Address:</h4>  H no. A-57, Street no. 3, Churriya Mohalla, Tughalakabad Village, New Delhi -110044</p>
                </li>
            </ul>
            <p>By using our website and services, you consent to the terms of this Privacy Policy.</p>
        </div>
    );
};

export default PrivacyPolicy;
