import React , { useEffect, useState, useRef,useCallback} from 'react';
import axios from 'axios';

import TajMahal from '../images/AITajMahal.jpeg';
import EiffelTower from '../images/eiffelTower.jpeg'
import GoldenPavilion from '../images/AIGoldenPavilion.jpeg';
import SatueOfLiberty from '../images/SOL.jpeg';
import AmberFort from '../images/AmberFort.jpeg';

import './CitiesDesc.css';
import ReviewForm from './ReviewForm';
import Select from 'react-select';
import TourGuideReviewsSection from './TourGuideReviewsSection';
import ErrorMessage from './HomePage/ErrorMessage';

function ExploreCitiesMore({selectedCountryName,selectedCityName,touristPlace,setIsNewTagsVisible}) {

  const screenWidth = window.innerWidth;

  // const languages = [
  //   { name: "Albanian", country: "Albania" },
  //   { name: "Arabic", country: "Arab World" },
  //   { name: "Armenian", country: "Armenia" },
  //   { name: "Awadhi", country: "India" },
  //   { name: "Azerbaijani", country: "Azerbaijan" },
  //   { name: "Bashkir", country: "Russia" },
  //   { name: "Basque", country: "Spain" },
  //   { name: "Belarusian", country: "Belarus" },
  //   { name: "Bengali", country: "Bangladesh" },
  //   { name: "Bhojpuri", country: "India" },
  //   { name: "Bosnian", country: "Bosnia and Herzegovina" },
  //   { name: "Brazilian Portuguese", country: "Brazil" },
  //   { name: "Bulgarian", country: "Bulgaria" },
  //   { name: "Cantonese (Yue)", country: "China" },
  //   { name: "Catalan", country: "Spain" },
  //   { name: "Chhattisgarhi", country: "India" },
  //   { name: "Chinese", country: "China" },
  //   { name: "Croatian", country: "Croatia" },
  //   { name: "Czech", country: "Czech Republic" },
  //   { name: "Danish", country: "Denmark" },
  //   { name: "Dogri", country: "India" },
  //   { name: "Dutch", country: "Netherlands" },
  //   { name: "English", country: "United Kingdom" },
  //   { name: "Estonian", country: "Estonia" },
  //   { name: "Faroese", country: "Faroe Islands" },
  //   { name: "Finnish", country: "Finland" },
  //   { name: "French", country: "France" },
  //   { name: "Galician", country: "Spain" },
  //   { name: "Georgian", country: "Georgia" },
  //   { name: "German", country: "Germany" },
  //   { name: "Greek", country: "Greece" },
  //   { name: "Gujarati", country: "India" },
  //   { name: "Haryanvi", country: "India" },
  //   { name: "Hindi", country: "India" },
  //   { name: "Hungarian", country: "Hungary" },
  //   { name: "Indonesian", country: "Indonesia" },
  //   { name: "Irish", country: "Ireland" },
  //   { name: "Italian", country: "Italy" },
  //   { name: "Japanese", country: "Japan" },
  //   { name: "Javanese", country: "Indonesia" },
  //   { name: "Kannada", country: "India" },
  //   { name: "Kashmiri", country: "India" },
  //   { name: "Kazakh", country: "Kazakhstan" },
  //   { name: "Konkani", country: "India" },
  //   { name: "Korean", country: "South Korea" },
  //   { name: "Kyrgyz", country: "Kyrgyzstan" },
  //   { name: "Latvian", country: "Latvia" },
  //   { name: "Lithuanian", country: "Lithuania" },
  //   { name: "Macedonian", country: "North Macedonia" },
  //   { name: "Maithili", country: "India" },
  //   { name: "Malay", country: "Malaysia" },
  //   { name: "Maltese", country: "Malta" },
  //   { name: "Mandarin", country: "China" },
  //   { name: "Mandarin Chinese", country: "China" },
  //   { name: "Marathi", country: "India" },
  //   { name: "Marwari", country: "India" },
  //   { name: "Min Nan", country: "China" },
  //   { name: "Moldovan", country: "Moldova" },
  //   { name: "Mongolian", country: "Mongolia" },
  //   { name: "Montenegrin", country: "Montenegro" },
  //   { name: "Nepali", country: "Nepal" },
  //   { name: "Norwegian", country: "Norway" },
  //   { name: "Oriya", country: "India" },
  //   { name: "Pashto", country: "Afghanistan" },
  //   { name: "Persian (Farsi)", country: "Iran" },
  //   { name: "Polish", country: "Poland" },
  //   { name: "Portuguese", country: "Portugal" },
  //   { name: "Punjabi", country: "India" },
  //   { name: "Rajasthani", country: "India" },
  //   { name: "Romanian", country: "Romania" },
  //   { name: "Russian", country: "Russia" },
  //   { name: "Sanskrit", country: "India" },
  //   { name: "Santali", country: "India" },
  //   { name: "Serbian", country: "Serbia" },
  //   { name: "Sindhi", country: "Pakistan" },
  //   { name: "Sinhala", country: "Sri Lanka" },
  //   { name: "Slovak", country: "Slovakia" },
  //   { name: "Slovene", country: "Slovenia" },
  //   { name: "Slovenian", country: "Slovenia" },
  //   { name: "Ukrainian", country: "Ukraine" },
  //   { name: "Urdu", country: "Pakistan" },
  //   { name: "Uzbek", country: "Uzbekistan" },
  //   { name: "Vietnamese", country: "Vietnam" },
  //   { name: "Welsh", country: "Wales" },
  //   { name: "Wu", country: "China" },
  // ];

  const languages = [
    { name: "English (US)", country: "United States" },
    { name: "Arabic", country: "Arab World" },
    { name: "Catalan", country: "Spain" },
    { name: "Chinese Mandarin", country: "China" },
    { name: "Danish", country: "Denmark" },
    { name: "Dutch", country: "Netherlands" },
    { name: "English (Australian)", country: "Australia" },
    { name: "English (British)", country: "United Kingdom" },
    { name: "English (Indian)", country: "India" },
    { name: "English (New Zealand)", country: "New Zealand" },
    { name: "English (South African)", country: "South Africa" },
    { name: "English (Welsh)", country: "Wales" },
    { name: "French", country: "France" },
    { name: "French (Canadian)", country: "Canada" },
    { name: "German", country: "Germany" },
    { name: "Hindi", country: "India" },
    { name: "Icelandic", country: "Iceland" },
    { name: "Italian", country: "Italy" },
    { name: "Japanese", country: "Japan" },
    { name: "Korean", country: "South Korea" },
    { name: "Norwegian", country: "Norway" },
    { name: "Polish", country: "Poland" },
    { name: "Portuguese (Brazilian)", country: "Brazil" },
    { name: "Portuguese (European)", country: "Portugal" },
    { name: "Romanian", country: "Romania" },
    { name: "Russian", country: "Russia" },
    { name: "Spanish", country: "Spain" },
    { name: "Spanish (Mexican)", country: "Mexico" },
    { name: "Swedish", country: "Sweden" },
    { name: "Turkish", country: "Turkey" }
  ];
  

  
  const [userComfortableLanguages,setUserConfortableLanguages]=useState('English (US)');

  const [description,setDescription]=useState('');
  const [isBookTourGuideVisible,setIsBookTourGuideVisible]=useState(false);
  const [isListenAudioBookVisible,setIsListenAudioBookVisible]=useState(false);
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [errorInBookingGuideForm,setErrorInLookingGuideForm]=useState('');
  
  const [isChoosingTourGuidesAvailable,setIsChoosingTourGuidesAvailable]=useState(false);
  const [isChoosingTourGuidesReserved,setIsChoosingTourGuidesReserved]=useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedGuide, setSelectedGuide] = useState(null);  

  const [text, setText] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [isSelectionOfTourGuide,setIsSelectionOfTourGuide]=useState(false);
  const [tourGuides,setTourGuides]=useState([]);
  const [selectedIndex,setSelectedIndex]=useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [seekingReverted, setSeekingReverted] = useState(false);
  const initialPlaybackTime = useRef(0);
  const seekingPrevented = useRef(false);
  const audioRef = useRef(null);
  const [numPeople, setNumPeople] = useState(1);
  const [optionGivenToUserVisible,setOptionGivenToUserVisilble]=useState(false);
  const [isOptionGivenToUserSelected,setIsOptionGivenToUserSelected]=useState(false);
  const [selectedOption,setSelectedOption]=useState('');
  const [showTouristProfile,setShowTouristProfile]=useState(false);
  const [showChats, setShowChats]=useState(false);
  const [isInstantBookingVisible,setIsInstantBookingVisible]=useState(false);


  const [showFeedbackform,setShowFeedbackForm]=useState(false);

  const screenWindw=window.innerWidth;
  const [showGoogleAds, setShowGoogleAds] = useState(false);

  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({value: 'INR', label: 'INR - Indian Rupee'});
  const [showCurrencyChage,setShowCurrencyChange]=useState(false);


  const [offerPrice, setOfferPrice] = useState(10.0);
  const [errorMessage, setErrorMessage]=useState('');


  useEffect(() => {
    const fetchTouristPlace = async () => {
      const language = localStorage.getItem("languageDetail") || 'English (US)';
      try {
        const response = await fetch('/api/generate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ touristPlace, language })
        });
  
        const data = await response.json();
        setDescription(data.description);
        sessionStorage.setItem(`${touristPlace}text`, data.description);
        sessionStorage.setItem(`${touristPlace}textLanguage`, language);
      } catch (error) {
        console.error('Error in getting data:', error);
      }
    }
  
    // Conditions to determine whether to fetch data or load from session storage
    const storedText = sessionStorage.getItem(`${touristPlace}text`);
    const storedLanguage = sessionStorage.getItem(`${touristPlace}textLanguage`);
    const currentLanguage = localStorage.getItem("languageDetail") || 'English (US)';
  
    if (!storedText || storedLanguage !== currentLanguage) {
      fetchTouristPlace();
    } else {
      setDescription(storedText);
    }
  
    // Set user's comfortable language if different from English
    if (currentLanguage !== "English (US)") {
      setUserConfortableLanguages(currentLanguage);
    }
  
    // Only depend on touristPlace to re-trigger the effect when it changes
  }, [touristPlace]);
  

  const setBackgroundImage=()=>{
    if(touristPlace==='Taj Mahal'){
      return TajMahal;
    }
    else if(touristPlace==="Eiffel Tower"){
      return EiffelTower;
    }
    else if(touristPlace==="Golden Pavilion"){
      return GoldenPavilion;
    }
    else if(touristPlace==="Statue Of Liberty"){
      return SatueOfLiberty;
    }
    else if(touristPlace==="Amber Fort"){
      return AmberFort;
    }
    else{
      const ImageURL = localStorage.getItem('touristPlaceData');
      return ImageURL;
    }
  }
  const handleChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
  };

  const toggleBookTourGuide=()=>{
    if(selectedCountryName==="India"){
      setLocation('');
      setDate('');
      setTime('');
      setIsBookTourGuideVisible(!isBookTourGuideVisible);
    }

  };

  const fetchDataForAudioBook = async () => {
    const language=localStorage.getItem("languageDetail") || 'ENGLISH';
    if(localStorage.getItem('subscriptionDetail')==='Fortnight Plan - 11.99$/2-weeks' || localStorage.getItem('subscriptionDetail')==='Quadweek Plan - 19.99$/28-days'){
      try {
        const response = await fetch('/api/generateForAudioBook', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ touristPlace,language })
        });
  
        const data = await response.json();
        setText(data.description);
        return data.description;
      } catch (error) {
        //error handling
        //('Error in getting data:', error);
      }
    }else if((localStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedInByGoogle'))){
      try {
        const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
        const response = await fetch('/api/generateForAudioBookFreeVersion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ touristPlace ,email,language})
        });
  
        const data = await response.json();
         if (data.error) {
        alert(data.error);
        setDescription('');
      } else {
        setDescription(data.description);
        
      }
        return data.description;
      } catch (error) {
        if (error.response && error.response.data) {
          alert(`Error: ${error.response.data.error}`);
        } else {
          // alert('An unexpected error occurred');
        }
      }
    }else{
      if(!(localStorage.getItem('limit_reached_for_audio'))){
        localStorage.setItem("limit_reached_for_audio",0);
      }
      const limit_reached=localStorage.getItem("limit_reached_for_audio");

      if(limit_reached==="5"){
        alert("please log in ");

        return;
      }
      try {
        const response = await fetch('/api/generateForAudioBookFreeVersionWithOutLogIn', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ touristPlace,language})
        });
  
        const data = await response.json();
         if (data.error) {
        alert(data.error);
        setIsListenAudioBookVisible(false);
        setDescription('');
      } else {
        setDescription(data.description);
        const incrementResult=Number(limit_reached)+1;
        localStorage.setItem("limit_reached_for_audio",incrementResult);        
      }
        return data.description;
      } catch (error) {
        if (error.response && error.response.data) {
          alert(`Error: ${error.response.data.error}`);
        } else {
          // alert('An unexpected error occurred');
        }
      }


    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // const base64ToBlob = (base64) => {
  //   const byteString = atob(base64.split(',')[1]);
  //   const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    
  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const uint8Array = new Uint8Array(arrayBuffer);
    
  //   for (let i = 0; i < byteString.length; i++) {
  //     uint8Array[i] = byteString.charCodeAt(i);
  //   }

  //   return new Blob([uint8Array], { type: mimeString });
  // };


  const handleSynthesize = async (description) => {
    try {
      const language=localStorage.getItem("languageDetail") || 'ENGLISH';
      const response = await axios.post('/api/synthesize', { text: description,language: language }, {
        responseType: 'blob'
      });
      const base64Url = await blobToBase64(response.data);
      setAudioUrl(base64Url);
      // sessionStorage.setItem(`${touristPlace}audioURL`, base64Url);
    } catch (error) {
      //error handling
      //('Error synthesizing speech:', error);
    }
  };

  // const retrieveAudioUrl = () => {
  //   const base64Url = sessionStorage.getItem(`${touristPlace}audioURL`);
  //   if (base64Url) {
  //     const blob = base64ToBlob(base64Url);
  //     const url = URL.createObjectURL(blob);
  //     setAudioUrl(url);
  //   }
  // };

  useEffect(() => {
    const fetchTouristData = async () => {
        try {
            const response = await axios.post('/api/getTouristData');
            setTourGuides(response.data);
        } catch (error) {
            //<-- message handling -->
            // console.error('Error fetching tourist data:', error);
        }
    };

    fetchTouristData();
  }, []);

  // useEffect(() => {
  //   retrieveAudioUrl();
  // }, [touristPlace]);

  const toggleSelectionOfTourGuide= async ()=>{
    setIsChoosingTourGuidesAvailable(!isChoosingTourGuidesAvailable);
  }

  const toggleAudioBook=async()=>{
    setIsListenAudioBookVisible(!isListenAudioBookVisible);

    setShowGoogleAds(true);
    if (!audioUrl) {
      const textForAudio = await fetchDataForAudioBook();
      if (textForAudio) {
        await handleSynthesize(textForAudio);
      }
    }    
  };

  const truncateName = (name) => {
    const cleanName = name.replace(/\(.*?\)/g, '').split(',')[0];
    return cleanName.length > 18 ? `${cleanName.substring(0, 18)}...` : cleanName;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const selectedDate = new Date(`${date}T${time}`);

    if (selectedDate < currentDate) {
      setErrorInLookingGuideForm('The selected date and time cannot be in the past.');
      return;
    }
    const selectedTime = selectedDate.getHours();
    if (selectedTime < 7 || selectedTime >= 21) {
      setErrorInLookingGuideForm('Please select a time between 7am and 9pm.');
      return;
    }

    setErrorInLookingGuideForm('');
    const userBookingDetails = new Map();
    userBookingDetails.set('location', location);
    userBookingDetails.set('date', date);
    userBookingDetails.set('time', time);

    const dataObject = Object.fromEntries(userBookingDetails);

    localStorage.setItem('eventData', JSON.stringify(dataObject));
    setIsBookTourGuideVisible(false);
    setIsChoosingTourGuidesReserved(true);
  
     //--forgetting users data;
    // To alert the data (you can retrieve and alert it anytime later)
    // const storedData = JSON.parse(localStorage.getItem('eventData'));
    // alert(`Location: ${storedData.location}\nDate: ${storedData.date}\nTime: ${storedData.time}`);

    // To retrieve the data as a map
    // const retrievedMap = new Map(Object.entries(storedData));
  };

  const handleSubmitInstant=(e)=>{
    e.preventDefault();
    const currentDate = new Date();
    setDate(currentDate.toLocaleDateString());
    setTime(currentDate.toLocaleTimeString());


    setErrorInLookingGuideForm('');
    const userBookingDetails = new Map();
    userBookingDetails.set('location', `${touristPlace},${selectedCityName}`);
    userBookingDetails.set('date', date);
    userBookingDetails.set('time', time);

    const dataObject = Object.fromEntries(userBookingDetails);

    localStorage.setItem('eventData', JSON.stringify(dataObject));
    setIsInstantBookingVisible(false);
    setIsChoosingTourGuidesAvailable(true);
  }

  const getCurrentLocation = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            const response = await axios.get(`/api/geocode?lat=${latitude}&lng=${longitude}`);
            const address = response.data.results[0].formatted_address;
            setLocation(address);
            setErrorInLookingGuideForm('');
          },
          (error) => {
            //error handling
            //('Error getting geolocation:', error);
            //setErrorInLookingGuideForm('location access declined by you.');
          }
        );
      } else {
        // Error handling
        // ('Geolocation is not supported by this browser.');
        // setErrorInLookingGuideForm('Geolocation is not supported.');
      }
    } catch (error) {
      // error handling
      // ('Error fetching address:', error);
      // setErrorInLookingGuideForm('Failed to retrieve location details.');
    }
  };

  const handleSelect = (index) => {
    setSelectedIndex(index);
    setSelectedGuide(tourGuides[index]);
};


const onPlay = useCallback(() => {
  setIsPlaying(true);
  initialPlaybackTime.current = audioRef.current.currentTime;
  
  // Clear any existing timeout
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  
  // Automatically pause the audio after 40 seconds
  const id = setTimeout(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, 40000); // 40 seconds
  setTimeoutId(id);
}, [timeoutId]);

const handleSeeking = useCallback(() => {
  if (audioRef.current) {
    if (!seekingPrevented.current) {
      seekingPrevented.current = true;
      audioRef.current.currentTime = initialPlaybackTime.current;
    } else {
      seekingPrevented.current = false;
    }
  }
}, [initialPlaybackTime.current]);

const handleTimeUpdate = useCallback(() => {
  if (audioRef.current) {
    audioRef.current._lastTime = audioRef.current.currentTime;
  }
}, []);

useEffect(() => {
  const fetchCurrencies = async () => {
    try {
      const response = await axios.get('/api/currencies');
      const options = Object.keys(response.data).map((key) => ({
        value: key,
        label: `${key} - ${response.data[key]}`,
      }));
      setCurrencies(options);
    } catch (error) {
      console.error('Error fetching currencies:', error);
    }
  };

  fetchCurrencies();
}, []);

const handleChangeLanguage = (event) => {
  const inputValue = event.target.value;
  localStorage.setItem('languageDetail', inputValue);
  setUserConfortableLanguages(inputValue);
  window.location.reload();
};

// useEffect(() => {
//   const audioElement = audioRef.current;
//   if (audioElement) {
//     audioElement.addEventListener('play', onPlay);
//     audioElement.addEventListener('seeking', handleSeeking);
//     audioElement.addEventListener('timeupdate', handleTimeUpdate);

//     return () => {
//       audioElement.removeEventListener('play', onPlay);
//       audioElement.removeEventListener('seeking', handleSeeking);
//       audioElement.removeEventListener('timeupdate', handleTimeUpdate);

//       if (timeoutId) {
//         clearTimeout(timeoutId);
//       }
//     };
//   }
// }, [audioUrl, onPlay, handleSeeking, handleTimeUpdate, timeoutId]);

useEffect(() => {
  if (seekingReverted && isPlaying) {
    audioRef.current.play();
    setSeekingReverted(false);
  }
}, [seekingReverted, isPlaying]);

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    fontSize:'14px',
    height:'50px'
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    height:'45px'
  }),
  menu: (provided) => ({
    ...provided,
    maxHeight: 300,
    overflowY: 'auto',
  }),
};


const handlePayment = async (e) => {
  e.preventDefault();
  const amountInUSD = selectedGuide.price*numPeople;
  let amount;
  const preferredCurrency = selectedCurrency.value;
  try {
    if(preferredCurrency!=='USD'){
      const response = await axios.post('/api/processPayment', {
          amountInUSD,
          userPreferredCurrency: preferredCurrency,
      });
      amount=parseFloat(response.data.amountInPreferredCurrency.toFixed(2)); ; 
    }
    else{
        amount=amountInUSD;
    }
  } catch (error) {
  }

  const order = await axios.post('/api/create-order', { amount,preferredCurrency });
  const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
  const touristMail=(storedIsLoggedIn)?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');

  const options = {
    
    key: 'rzp_live_DqIp2pXc46hk0k',
    amount: order.data.amount,
    currency: preferredCurrency,
    name: 'ADVENTROZ',
    description: 'Test Transaction',
    image: 'https://adventroz.com/favicon.ico',

    order_id: order.data.id,
    handler: async (response) => {
      // Verify payment signature
      const data = {
        razorpay_order_id: response.razorpay_order_id,
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_signature: response.razorpay_signature,
      };

      const result = await axios.post('/api/verify-signature', data);
      if (result.data.status === 'success') {
        alert('Payment successful');
        setIsChoosingTourGuidesAvailable(false);
        submitForPay(amount);

      } else {
        alert('Payment verification failed');
      }
    },
    prefill: {
      email: touristMail,
    },
    theme: {
      color: '#F37254',
    },
  };

  const rzp = new window.Razorpay(options);
  rzp.open();
};

const submitForPay=(amountPayed)=>{
  const selectedGuideMail=selectedGuide.emailAddress;
  const storedData = JSON.parse(localStorage.getItem('eventData'));

    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    
    const touristMail=(storedIsLoggedIn)?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');

    axios.post('/api/send-email', {
      touristMail,
      guideMail: selectedGuideMail,
      guideName:selectedGuide.legalname,
      eventData: storedData,
      amount:amountPayed,
      place:touristPlace
    })

}

  const checkForSameMail=(tourGuideMail)=>{
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if(storedIsLoggedIn){
      const touristMail=(storedIsLoggedIn)?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
      if(touristMail===tourGuideMail){
        return false;
      }
      else{
        return true;
      }
    }
    else{
      return true;
    }
  }


  const handlePriceChange = (e) => {
      const value = parseFloat(e.target.value);
      setOfferPrice(value);
  };

  
  const handleSubmitForPriceChange = async (e) => {
    e.preventDefault();
    if (offerPrice>10 && offerPrice<20) {
        const formDataToSend = new FormData();
        formDataToSend.append('clientId', (localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle'));
        formDataToSend.append('guideId', selectedGuide.emailAddress);
        formDataToSend.append('clientName', localStorage.getItem("username"));
        formDataToSend.append('guideName', `${selectedGuide.firstName} ${selectedGuide.lastName}`);
        formDataToSend.append('touristPlace',touristPlace);
        formDataToSend.append('originalPrice',selectedGuide.guidePrice);
        formDataToSend.append('offerPrice',offerPrice);
        formDataToSend.append('noOfPeople',numPeople);
     

        try {
          const response = await fetch('/api/set-negotiations', {
            method: 'POST',
            body: formDataToSend,
          });
      
          if (response.ok) {
            setShowChats(false);
            alert('Send Successfully');
          } else {
            // setErrorMessage('profile photo size is too large.');
          }
        } catch (error) {
            setErrorMessage('Error:', error);
        }
        
    }else{
      setErrorMessage('Price must be bwtween (10 to 20)');
    }
  };


    return (
        <div className="explore-container">
          <img  src={setBackgroundImage()} alt={touristPlace} style={{objectFit: 'cover', top:'0', marginLeft:(screenWindw>450)?'2.5rem':'0rem'}} className='city-image'/>
            <div className="text-container">
              <h1>{touristPlace}</h1>
              <select id="language" value={userComfortableLanguages} onChange={handleChangeLanguage}
                                            style={{
                                                padding: '8px',
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                fontSize: screenWidth>517?'16px':'10px',
                                                width: screenWidth>517?'250px':'63%',
                                                boxSizing: 'border-box',
                                              }}
                                        
                                        >
                                            {languages.map((language, index) => (
                                            <option key={index} value={language.name}>
                                                {language.name} - {language.country}
                                            </option>
                                            ))}
                                        </select>
              <p style={{textAlign:'left'}}>{description}</p>

              <div className='audio-book'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-headphones" viewBox="0 0 16 16">
                  <path d="M8 3a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a6 6 0 1 1 12 0v5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1V8a5 5 0 0 0-5-5"/>
                </svg><p>Explore more with our Audio Book.</p>
              </div>    
          </div>
          <button onClick={(toggleAudioBook)} className='book-tour-guide'>Listen an Audio Book</button>
          <button style={{paddingLeft:'30px', paddingRight:'30px', display:(selectedCountryName==="India")?"block":"none", backgroundColor:(selectedCountryName==="India")?'black':'grey'}} onClick={()=>setOptionGivenToUserVisilble(true)} className='book-tour-guide'>{(selectedCountryName!=="India")?'Booking N/A':'Book a tour Guide'}</button>
          <button onClick={()=>setShowFeedbackForm(true)} className='book-tour-guide'>Submit Your Feedback</button>

          {showFeedbackform &&(
            <ReviewForm closeReviewForm={()=>setShowFeedbackForm(false)} setIsNewTagsVisible={setIsNewTagsVisible}/>
          )}
          {optionGivenToUserVisible &&(
            <div className='book-tour-operator'>
            <div className='select-option-given-to-user'>
            <div onClick={()=>setOptionGivenToUserVisilble(false)} className='closeLoginAndSignUpPopUp'>X</div>
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><h2 style={{margin:'0px', marginBottom:'5px'}}>Choose a option</h2></div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'200px', gap:'20px'}}>
              <div className='option-given' onClick={()=>{setIsOptionGivenToUserSelected(true); setSelectedOption('instant')}} style={{border:(selectedOption==='instant')?'2px solid black':'2px solid grey'}}>
                <div id='instant-booking' className='image-section-of-option-given'>

                </div>
                Instant Booking
              </div>
              <div className='option-given' onClick={()=>{setIsOptionGivenToUserSelected(true); setSelectedOption('reserved')}} style={{border:(selectedOption==='reserved')?'2px solid black':'2px solid grey'}}>
              <div id='reserved-booking' className='image-section-of-option-given'>
                
              </div>
              Reserved Booking
              </div>
            </div>
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', marginTop:'10px'}}>
              <button id='fialalize-select-option' onClick={()=>{
                if(selectedOption==='reserved'){
                  setIsBookTourGuideVisible(true);
                }
                if(selectedOption==='instant'){
                  setIsInstantBookingVisible(true);
                }
                setOptionGivenToUserVisilble(false)}} 
                style={{backgroundColor:isOptionGivenToUserSelected?'black':'grey'}}>Confirm Your Choice</button>
            </div>
            
            </div>
          </div>
          )}
          {isInstantBookingVisible && (
            <div className='book-tour-operator'>
              <div style={{height:'270px', width:'400px'}} className='popUp-booking-tour-guide'>
                <div onClick={()=>setIsInstantBookingVisible(false)} className='closeLoginAndSignUpPopUp'>X</div>
                <form onSubmit={handleSubmitInstant} className="pickup-form">
                <h1>{touristPlace}</h1>
                <div>
                  <label>
                    Number of People: 
                    <input type="number" min="1" value={numPeople} onChange={(e) => setNumPeople(e.target.value)} required />
                  </label>
                </div>
                <button type="submit">Next &rarr;</button>
                <p className='errorInEmail'>{errorInBookingGuideForm}<br /></p>
              </form>
              </div>
            </div>
          )
          }
          {isBookTourGuideVisible &&(
            <div className='book-tour-operator'>
            <div className='popUp-booking-tour-guide'>
              <div onClick={toggleBookTourGuide} className='closeLoginAndSignUpPopUp'>X</div>
              <form onSubmit={handleSubmit} className="pickup-form">
                <h2>{touristPlace}</h2>
                <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                  <label style={{width:'90%', height:'35px'}}>
                    Pick Up Location:
                    <input style={{width:'106%'}} type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
                  </label>
                  <button 
                    style={{width:'30px', height:'32px', marginTop:'25px', marginLeft:'3px', padding:'0px', backgroundColor:'white'}} 
                    type='button' 
                    onClick={getCurrentLocation}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" fill="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="bi bi-crosshair" viewBox="0 -1 16 16">
                      <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7 7 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7 7 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7 7 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7 7 0 0 0 8.5 1.018zm-6.48 7A6 6 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6 6 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6 6 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6 6 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1zM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                    </svg>
                  </button>
                </div>
                <div>
                  <label> 
                    Date:
                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
                  </label>
                </div>
                <div>
                  <label> 
                    Time: 
                    <input type="time" value={time} onChange={(e) => setTime(e.target.value)} required />
                  </label>
                </div>
                <div>
                  <label> 
                    Number of People: 
                    <input type="number" min="1" value={numPeople} onChange={(e) => setNumPeople(e.target.value)} required />
                  </label>
                </div>
                <button type="submit">Next &rarr;</button>
                <p className='errorInEmail'>{errorInBookingGuideForm}<br /></p>
              </form>
            </div>
          </div>
          )}
          {isChoosingTourGuidesAvailable && (
             <div className='book-tour-operator'>
             <div className='popUp-booking-tour-guide'>
               <div onClick={toggleSelectionOfTourGuide} className='closeLoginAndSignUpPopUp'>X</div>
               <div className="grid-container">
                   {tourGuides.map((operator, index) => (
                       <div
                           key={index} style={{border:(selectedIndex===index)?'2px solid black':'none', display:operator.guidePlaces.toLowerCase().includes(touristPlace.toLowerCase())?'flex':'none'}}
                           className={`grid-item ${selectedIndex !== null && selectedIndex !== index ? 'blurred' : ''}`}
                           onClick={() => handleSelect(index)}>
                           <img src={`${operator.profilePicture}`} alt={operator.firstName} style={{height:'70px', width:'70px', marginBottom:'0px'}} className="profile-picture" />
                           <p style={{color:'rgb(202, 53, 190)'}} className="operator-name">{`${operator.firstName} ${operator.lastName}`}</p>
                           <p style={{marginTop:'-8px', marginBottom:'8px'}}>{operator.guidePrice} $</p>
                           <button style={{border:'none', borderRadius:'5px', border:'1px solid black', color:'white', backgroundColor:'black'}} onClick={()=>{setShowChats(true)}}>Negotiate</button>
                       </div>
                   ))}
               </div>
               <div style={{display:"flex",flexDirection:'row', alignItems:'center',justifyContent:"space-around"}}>
                <button disabled={selectedGuide===null} 
                style={{fontSize:(screenWindw<450)?(screenWindw<375)?'10px':'12px':'16px', color:(selectedGuide!==null)?'black':'grey', backgroundColor:'whitesmoke', border:'1px solid black'}} 
                onClick={()=>setShowTouristProfile(true)} className='book-tour-guide'>View Profile </button>
                <button disabled={selectedGuide===null} 
                style={{fontSize:(screenWindw<450)?(screenWindw<375)?'10px':'12px':'16px', backgroundColor:(selectedGuide!==null)?'black':'grey'}} 
                onClick={()=>setShowCurrencyChange(true)} className='book-tour-guide'>Proceed To Payment &rarr;</button>
              </div>
             </div>
           </div>
          )}
          {isChoosingTourGuidesReserved && (
             <div className='book-tour-operator'>
             <div className='popUp-booking-tour-guide'>
               <div onClick={()=>setIsChoosingTourGuidesReserved(false)} className='closeLoginAndSignUpPopUp'>X</div>
               <div className="grid-container">
                   {tourGuides.map((operator, index) => (
                       <div
                           key={index} style={{border:(selectedIndex===index)?'2px solid black':'none', display:operator.guideCity.toLowerCase().includes(selectedCityName.toLowerCase())?'flex':'none'}}
                           className={`grid-item ${selectedIndex !== null && selectedIndex !== index ? 'blurred' : ''}`}
                           onClick={() => handleSelect(index)}>
                           <img src={`${operator.profilePicture}`} alt={operator.firstName} style={{height:'70px', width:'70px', marginBottom:'0px'}} className="profile-picture" />
                           <p style={{color:'rgb(202, 53, 190)'}} className="operator-name">{`${operator.firstName} ${operator.lastName}`}</p>
                           <p style={{marginTop:'-8px', marginBottom:'8px'}}>{operator.guidePrice} $</p>
                           <button style={{border:'none', borderRadius:'5px', border:'1px solid black', color:'white', backgroundColor:'black'}} onClick={()=>{setShowChats(true)}}>Negotiate</button>
                       </div>
                   ))}
               </div>
               <div style={{display:"flex",flexDirection:'row', alignItems:'center',justifyContent:"space-around"}}>
                <button disabled={selectedGuide===null} 
                style={{fontSize:(screenWindw<450)?(screenWindw<375)?'10px':'12px':'16px', color:(selectedGuide!==null)?'black':'grey', backgroundColor:'whitesmoke', border:'1px solid black'}} 
                onClick={()=>setShowTouristProfile(true)} className='book-tour-guide'>View Profile </button>
                <button disabled={selectedGuide===null} 
                style={{fontSize:(screenWindw<450)?(screenWindw<375)?'10px':'12px':'16px', backgroundColor:(selectedGuide!==null)?'black':'grey'}} 
                onClick={()=>setShowCurrencyChange(true)} className='book-tour-guide'>Proceed To Payment &rarr;</button>
              </div>
             </div>
           </div>
          )}
          {showTouristProfile && (
                <TourGuideReviewsSection onClose={()=>setShowTouristProfile(false)} selectedGuide={selectedGuide}/>
          )}
          {showChats && (
            // <UserMessages user={localStorage.getItem('isLoggedIn')?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle')}  setUserMessagesVisible={()=>setShowChats(false)} receiver={selectedGuide}/>
            <div className='book-tour-operator'>
              <div className='book-tour-price'>
                <div onClick={()=>setShowChats(false)} className='closeLoginAndSignUpPopUp'>X</div>
                  <h2>Negotiate Your Offer</h2>
                  <form onSubmit={handleSubmitForPriceChange}>
                    <div className='form-group'>
                      <label>Original Price: ${selectedGuide.guidePrice}</label>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='offerPrice'>Enter your offer (between $10 and $20):</label>
                      <input
                        type='number'
                        id='offerPrice'
                        min='10'
                        max='20'
                        step='0.01'
                        value={offerPrice}
                        onChange={handlePriceChange}
                      />
                    </div>
                    <button type='submit' className='submit-btn'>
                      Submit Negotiation
                    </button>
                  </form>
              </div>
            </div>
              )}
          {isListenAudioBookVisible &&(
            <div className='book-tour-operator'>
            <div className='popUp-audio-book'>
              <div style={{float:'right', marginBottom:'-15px'}} onClick={toggleAudioBook} className='closeLoginAndSignUpPopUp'>X</div>
              <div className='popUp-audio-book-inner'>
                <span className='location-to-reach-place' style={{display:'flex', flexDirection:"row", justifyContent:"center",alignContent:"center"}}>
                    <a 
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(touristPlace)}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                    >
                  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="40" viewBox="0 0 432597 433333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
                    <path d="M151444 5419C140355 1916 128560 0 116311 0 80573 0 48591 16155 27269 41534l54942 46222 69232-82338z" fill="#1a73e8"/>
                    <path d="M27244 41534C10257 61747 0 87832 0 116286c0 21876 4360 39594 11517 55472l70669-84002-54942-46222z" fill="#ea4335"/>
                    <path d="M116311 71828c24573 0 44483 19910 44483 44483 0 10938-3957 20969-10509 28706 0 0 35133-41786 69232-82313-14089-27093-38510-47936-68048-57286L82186 87756c8166-9753 20415-15928 34125-15928z" fill="#4285f4"/>
                    <path d="M116311 160769c-24573 0-44483-19910-44483-44483 0-10863 3906-20818 10358-28555l-70669 84027c12072 26791 32159 48289 52851 75381l85891-102122c-8141 9628-20339 15752-33948 15752z" fill="#fbbc04"/>
                    <path d="M148571 275014c38787-60663 84026-88210 84026-158728 0-19331-4738-37552-13080-53581L64393 247140c6578 8620 13206 17793 19683 27900 23590 36444 17037 58294 32260 58294 15172 0 8644-21876 32235-58320z" fill="#34a853"/>
                  </svg>
                  </a>
                 <h2>{truncateName(touristPlace)}</h2>
                </span>
                <img  src={setBackgroundImage()} alt={touristPlace} style={{ width: '80%', height: '310px', objectFit: 'cover', top:'0'}} />
                <div className="audio-container">
                  {audioUrl? <audio className="audio-element" controls src={audioUrl} controlsList="nodownload"/>:null}
                  {audioUrl? null:<div className="div-element">Loading...</div>}
                </div>
              </div>
            </div>
          </div>
          )}
          {showCurrencyChage && (
            <div className='book-tour-operator'>
              <div style={{height:'300px', width:'400px'}} className='popUp-booking-tour-guide'>
                <div onClick={()=>setShowCurrencyChange(false)} className='closeLoginAndSignUpPopUp'>X</div>
                <h2 style={{marginTop:'20px', paddingLeft:'30px'}}>{touristPlace}</h2>
                <form onSubmit={handlePayment} className="pickup-form">
                <Select style={{containerWidth:'10%'}} type="text" value={selectedCurrency} onChange={handleChange} styles={customStyles} options={currencies} name="address" placeholder="Select Currency"/>
                <button type="submit">Proceed To Pay </button>
                                        

                {/* <h1>{touristPlace}</h1>
                <div>
                  <label>
                    Number of People: 
                    <input type="number" min="1" value={numPeople} onChange={(e) => setNumPeople(e.target.value)} required />
                  </label>
                </div>
                <h3>Price -: 10.99$/person</h3>
                <p style={{marginTop:"-10px",fontSize:'10px'}}>*(including tickets, 10 min audio book amd ADVENTROZ Guide)</p>
                <button type="submit">Next &rarr;</button>
                <p className='errorInEmail'>{errorInBookingGuideForm}<br /></p> */}
              </form>
              </div>
            </div>
          )
          }
          {errorMessage!==''  && (<ErrorMessage message={errorMessage} onClose={()=>setErrorMessage('')}/>)}
          {/* {showGoogleAds && (<GoogleAds closeGoogleAds={closeGoogleAds} />)} */}
        </div>
      );
}

export default ExploreCitiesMore;
