import React, { useEffect, useState } from 'react';
import NavBar from '../NavBar';
import './TourPlans.css'; // Assuming you have styles for your component

function TourPlans() {
  const [tourPlans, setTourPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchTourPlans = async () => {
//       try {
//         const response = await fetch('/api/tour-plans');
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         setTourPlans(data);
//       } catch (err) {
//         console.error('Failed to fetch tour plans:', err);
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTourPlans();
//   }, []);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error fetching tour plans: {error.message}</p>;

  return (
    <section>
      <NavBar />
      <div className='tour-plans-list'>
        {tourPlans.length > 0 ? (
          tourPlans.map((plan) => (
            <div key={plan.id} className='tour-plan'>
              <img src={plan.tourGuideImage} alt={`${plan.tourGuideName}'s Profile`} className='tour-guide-image' />
              <h3>{plan.startCity}</h3>
              <p><strong>Places:</strong> {plan.place}</p>
              <p><strong>Price:</strong> ${plan.price}</p>
              <p><strong>Start Date:</strong> {new Date(plan.startDate).toLocaleString()}</p>
              <p><strong>End Date:</strong> {new Date(plan.endDate).toLocaleString()}</p>
            </div>
          ))
        ) : (
          <div style={{width:'100%', display:"flex", alignItems:"center", justifyContent:"center", marginTop:'100px'}}>
          <p>Sorry for Inconvience No tour plans are available for now</p>
          </div>
        )}
      </div>
    </section>
  );
}

export default TourPlans;
