import React, {useState,useEffect} from 'react';
import MainPage from './HomePage/mainPage';
import Pagetwo from './HomePage/pageTwo';
import Pagethree from './HomePage/pageThree';
import Pagefour from './HomePage/PageFour';
import FinalPage from './HomePage/finalPage';
import NavBar from './NavBar';
import Popup from './Popup';

function Home() {

  const [showPopup, setShowPopup] = useState(false);
  const screenWidth=window.innerWidth;

  const handelSetSelectedCountryName = (value) => {
    localStorage.setItem('setSelectedCountryName', JSON.stringify(value));
  };

  const handelSetSelectedCityName = (value) => {
    localStorage.setItem('setSelectedCityName', JSON.stringify(value));
  };

  useEffect(() => {
    const isPopupShown = sessionStorage.getItem('popupShown');
    if (!isPopupShown) {
      setShowPopup(true);
      sessionStorage.setItem('popupShown', 'yes');
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const [isNewTagsVisible,setIsNewTagsVisible]=useState(false);

  return (
    <div>
        <>
          {showPopup &&<Popup closePopup={closePopup} />}
          <div id="home"><NavBar isNewTagsVisible={isNewTagsVisible} setIsNewTagsVisible={setIsNewTagsVisible} /></div>
          <div id="home"><MainPage/></div>
          <div id="home"><Pagetwo/></div>
          <div id="home"><Pagethree/></div>
          <div id="explore"><Pagefour setIsNewTagsVisible={setIsNewTagsVisible} setSelectedCountryName={handelSetSelectedCountryName} setSelectedCityName={handelSetSelectedCityName}/></div>
          <div id="contact"><FinalPage setIsNewTagsVisible={setIsNewTagsVisible}/></div>
        </>
    </div>
  );
}

export default Home;
