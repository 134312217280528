import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import './NotificationPanel.css';
import Select from 'react-select';
import ErrorMessage from './HomePage/ErrorMessage';
import axios from 'axios';

function NotificationPanel({isclose}) {
    const [touristNotifications, setTouristNotifications] = useState([]);

    const [guideNotifications, setGuideNotifications]=useState([]);

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    const [error,setError]=useState('');


    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState({value: 'INR', label: 'INR - Indian Rupee'});
    const [showCurrencyChage,setShowCurrencyChange]=useState(false);

    const makeDecision = (notification) => {
        setSelectedNotification(notification);
        if(notification.descision==='pending...'){
            setModalOpen(true);
        }
    };

    const fetchCurrencies = async () => {
      try {
        const response = await axios.get('/api/currencies');
        const options = Object.keys(response.data).map((key) => ({
          value: key,
          label: `${key} - ${response.data[key]}`,
        }));
        setCurrencies(options);
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    const handleDecision = async(id, decision) => {

        try {
            const response = await fetch('/api/save-decision', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, decision }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to save decision');
            }
        } catch (error) {
            console.error('Error saving decision:', error);
        }
        setModalOpen(false);
        isclose();
    };

    useEffect(() => {
        const fetchTouristNotifications = async () => {
            try {
                const storedEmail = localStorage.getItem('loggedInEmail') || localStorage.getItem('loggedInEmailGoogle');
                const response = await fetch(`/api/get-notifications-tourist?email=${storedEmail}`);
                const data = await response.json();
                setTouristNotifications(data);

            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        const fetchGuideNotifications = async () => {
            try {
                const storedEmail = localStorage.getItem('loggedInEmail') || localStorage.getItem('loggedInEmailGoogle');
                const response = await fetch(`/api/get-notifications-guide?email=${storedEmail}`);
                const data = await response.json();
                setGuideNotifications(data);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchTouristNotifications();
        fetchGuideNotifications();
    }, []);

    const makePayment=(notification)=>{
        if(notification.descision==='accept'){
            setSelectedNotification(notification);
            fetchCurrencies();
            setShowCurrencyChange(true);
        }
        else if(notification.descision==='pending...'){
            setError('Please wait for guides response');
        }
    }

    const handleChange = (selectedOption) => {
        setSelectedCurrency(selectedOption);
    };

    const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '100%',
          fontSize:'14px',
          height:'50px'
        }),
        control: (provided) => ({
          ...provided,
          width: '100%',
          height:'45px'
        }),
        menu: (provided) => ({
          ...provided,
          maxHeight: 300,
          overflowY: 'auto',
        }),
      };

      const handlePayment = async (e) => {
        e.preventDefault();
        const amountInUSD = selectedNotification.Payment*selectedNotification.numPeople;
        let amount;
        const preferredCurrency = selectedCurrency.value;
        try {
          if(preferredCurrency!=='USD'){
            const response = await axios.post('/api/processPayment', {
                amountInUSD,
                userPreferredCurrency: preferredCurrency,
            });
            amount=parseFloat(response.data.amountInPreferredCurrency.toFixed(2)); ; 
          }
          else{
              amount=amountInUSD;
          }
        } catch (error) {
        }
      
        const order = await axios.post('/api/create-order', { amount,preferredCurrency });
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
        const touristMail=(storedIsLoggedIn)?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
      
        const options = {
          
          key: 'rzp_live_DqIp2pXc46hk0k',
          amount: order.data.amount,
          currency: preferredCurrency,
          name: 'ADVENTROZ',
          description: 'Test Transaction',
          image: 'https://adventroz.com/favicon.ico',
      
          order_id: order.data.id,
          handler: async (response) => {
            // Verify payment signature
            const data = {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            };
      
            const result = await axios.post('/api/verify-signature', data);
            if (result.data.status === 'success') {
              alert('Payment successful');
              submitForPay(amount);
      
            } else {
              alert('Payment verification failed');
            }
          },
          prefill: {
            email: touristMail,
          },
          theme: {
            color: '#F37254',
          },
        };
      
        const rzp = new window.Razorpay(options);
        rzp.open();
      };
      
      const submitForPay=(amountPayed)=>{
        const selectedGuideMail=selectedNotification.guideMail;
        const storedData = JSON.parse(localStorage.getItem('eventData'));
      
          const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
          
          const touristMail=(storedIsLoggedIn)?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
      
          axios.post('/api/send-email', {
            touristMail,
            guideMail: selectedGuideMail,
            guideName:selectedNotification.guideName,
            eventData: storedData,
            amount:amountPayed,
            place:selectedNotification.touristPlace
          })
      
      }

    return (
        <div className='notification-panel'>
            <div style={{display:'flex', backgroundColor:'#6a3333', justifyContent:'space-between',alignItems:'center'}}>
                <h2>Notifications</h2>
                <div style={{float:'left', marginTop:'0px',color:'white', fontWeight:'400',fontSize:'20px'}} onClick={isclose} className='closeLoginAndSignUpPopUp'>X</div>
            </div>
            <div className='notification-list'>
                {(touristNotifications.length > 0 || guideNotifications.length>0)? (
                    <>
                    {touristNotifications.map((notification) => (
                        <div
                            key={notification.id}
                            className='notification-item'
                            onClick={() => makePayment(notification)}
                        >
                            <p style={{ color: 'black' }} className='notification-message'>{notification.message}</p>
                            <span className='notification-timestamp'>{notification.timestamp}</span>
                        </div>
                    ))}
            
                    {guideNotifications.map((notification) => (
                        <div
                            key={notification.id}
                            className='notification-item'
                            onClick={() => makeDecision(notification)}
                        >
                            <p style={{ color: 'black' }} className='notification-message'>{notification.message}</p>
                            <span className='notification-timestamp'>{notification.timestamp}</span>
                        </div>
                    ))}
                </>
                ) : (
                    <p>No notifications</p>
                )}
                <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                notification={selectedNotification}
                onDecision={handleDecision}
            />
            </div>
            {error!=='' && (<ErrorMessage message={error} onClose={()=>setError('')}/>)}
            {showCurrencyChage && (
            <div className='book-tour-operator'>
              <div style={{height:'300px', width:'400px'}} className='popUp-booking-tour-guide'>
                <div onClick={()=>setShowCurrencyChange(false)} className='closeLoginAndSignUpPopUp'>X</div>
                <h2 style={{marginTop:'20px', paddingLeft:'30px', fontSize:'40px', color:'blue'}}>{selectedNotification.touristPlace
                }</h2>
                <form onSubmit={handlePayment} className="pickup-form">
                <Select style={{containerWidth:'10%'}} type="text" value={selectedCurrency} onChange={handleChange} styles={customStyles} options={currencies} name="address" placeholder="Select Currency"/>
                <button type="submit">Proceed To Pay </button>
                                        

                {/* <h1>{touristPlace}</h1>
                <div>
                  <label>
                    Number of People: 
                    <input type="number" min="1" value={numPeople} onChange={(e) => setNumPeople(e.target.value)} required />
                  </label>
                </div>
                <h3>Price -: 10.99$/person</h3>
                <p style={{marginTop:"-10px",fontSize:'10px'}}>*(including tickets, 10 min audio book amd ADVENTROZ Guide)</p>
                <button type="submit">Next &rarr;</button>
                <p className='errorInEmail'>{errorInBookingGuideForm}<br /></p> */}
              </form>
              </div>
            </div>
          )
          }
        </div>
    );
}

export default NotificationPanel;
