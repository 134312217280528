import React from 'react';
import './mainPage.css';

const MainPage = () => {
  return (
    <section className='Home'>
      <div className='tranparency'>
        <div>
          <h1 className="media-query-discover">Discover the world with our amazing tours</h1>
          <p>Experience the wonders of popular tourist destination places</p>
        </div>
      </div>
    </section>
  );
};

export default MainPage;
