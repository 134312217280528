import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import ExploreBasedOnCityName from './Components/ExploreBasedOnCityName';
import ExploreBasedOnExploreClick from './Components/ExploreBasedOnExploreClick';
import TermsAndCondition from './Components/TermsAndCondition';
import PrivacyPolicy from './Components/PrivacyPolicy';
import ScrollToTop from './ScrollToTop';
import ContactUs from './Components/ContactUs';
import Disclaimer from './Components/Disclaimer';
import RefundAndCancellationPolicy from './Components/RefundAndCancellationPolicy';
import SubscriptionPlan from './Components/SubscriptionPlan';
import NotFound from './Components/NotFound';
import TourGuideProfile from './Components/TourGuide/TourGuideProfile';
import TourPlans from './Components/TourPlans/TourPlans';
import UserReview from './Components/UserReview';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route  path="/" element={<Home />} />
        <Route path= "/ExploreMore" element={<ExploreBasedOnCityName />} />
        <Route path='/terms_and_condition' element={<TermsAndCondition/>}/>
        <Route path='/Privacy_Policy' element={<PrivacyPolicy/>}/>
        <Route path= "/Explore" element={<ExploreBasedOnExploreClick />} />
        <Route path="/ContactUs" element={<ContactUs/>}/>
        <Route path="/Disclaimer" element={<Disclaimer/>}/>
        <Route path="/RefundAndCacellationPolicy" element={<RefundAndCancellationPolicy/>}/>
        <Route path='/Subscription' element={<SubscriptionPlan/>}/>
        <Route path="/tourGuideProfile" element={<TourGuideProfile/>}/>
        <Route path="/tourPlans" element={<TourPlans/>}/>
        <Route path='/userReview' element={<UserReview/>}/>
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
