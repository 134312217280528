import React,{useEffect, useState} from "react";
import './SubscriptionPlan.css';
import NavBar from "./NavBar";
import FinalPage from "./HomePage/finalPage";
import Select from 'react-select';
import axios from "axios";
import ErrorMessage from "./HomePage/ErrorMessage";

const SubscriptionPlan=()=>{

    const [showPopup, setShowPopup] = useState(false);
    const [showCurrencyChage,setShowCurrencyChange]=useState(false);
    const [selectedPlan,setSelectedPlan]=useState('Free Plan');
    const [selectedPrice,setSelectedPrice]=useState('0$/month');
    const [selectedPlanPrice,setSelectedPlanPrice]=useState(0);

    const [currencies, setCurrencies] = useState([]);

    const [userSubscription,setUserSubscription]=useState('Free Plan - 0$/month');

    const [selectedCurrency, setSelectedCurrency] = useState({value: 'INR', label: 'INR - Indian Rupee'});

    const [couponCode, setCouponCode]=useState('');

    const [discounted_percentage, setDiscounted_percentage]=useState(0);

    const [error, setError]=useState('');


    useEffect(() => {
        const isPopupShown = sessionStorage.getItem('popupShown');
        if (!isPopupShown) {
        setShowPopup(true);
        sessionStorage.setItem('popupShown', 'yes');
        }
    }, []);

    useEffect(() => {
        const fetchCurrencies = async () => {
          try {
            const response = await axios.get('/api/currencies');
            const options = Object.keys(response.data).map((key) => ({
              value: key,
              label: `${key} - ${response.data[key]}`,
            }));
            setCurrencies(options);
            console.log(options)
          } catch (error) {
            console.error('Error fetching currencies:', error);
          }
        };
    
        fetchCurrencies();
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };

    const [isNewTagsVisible,setIsNewTagsVisible]=useState(false);

    const setPriceInNum=(selectedPlan)=>{
        if(selectedPlan==='Free Plan'){
            setSelectedPlanPrice(0);
        }
        else if(selectedPlan==='Fortnight Plan'){
            setSelectedPlanPrice(11.99);
        }
        else if(selectedPlan==='Quadweek Plan'){
            setSelectedPlanPrice(19.99);
        }
    }

    const handleSubmitInstant=()=>{

    }

    const SubscriptionPlans=[
        {
            planName:'Free Plan',
            price:'0$/month',
            benifits:[
                {
                    one: '1 min audio',
                    two: '10 uses per day',
                    three: 'Audio & description in any language',
                    four: 'Explore worldwide',
                    five: 'Direct Google Maps access',
                    six: 'Always something fresh to hear',
                }
            ],
            purchase:localStorage.getItem('subscriptionDetail') && localStorage.getItem('subscriptionDetail') !== 'Free Plan - 0$/month' ? 'Paused' : 'in use'
        },
        {
            planName:'Fortnight Plan',
            price:'11.99$/2-weeks',
            benifits:[
                {
                    one: '90 min audio',
                    two: 'No daily limit',
                    three: 'Audio  & description in any language',
                    four: 'Explore worldwide',
                    five: 'Direct Google Maps access',
                    six: 'Always something fresh to hear',
                }
            ],
            purchase:localStorage.getItem('subscriptionDetail')!=='Fortnight Plan - 11.99$/2-weeks'?'Purchase':'in use'
        },
        {
            planName:'Quadweek Plan',
            price:'19.99$/28-days',
            benifits:[
                {
                    one: '180 min audio',
                    two: 'No daily limit',
                    three: 'Audio  & description in any language',
                    four: 'Explore worldwide',
                    five: 'Direct Google Maps access',
                    six: 'Always something fresh to hear',
                }
            ],
            purchase:localStorage.getItem('subscriptionDetail')!=='Quadweek Plan - 19.99$/28-days'?'Purchase':'in use'
        }
    ]
    const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

    const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '100%',
          fontSize:'14px',
          height:'50px'
        }),
        control: (provided) => ({
          ...provided,
          width: '100%',
          height:'45px'
        }),
        menu: (provided) => ({
          ...provided,
          maxHeight: 300,
          overflowY: 'auto',
        }),
      };

    useEffect(() => {
        const handleResize = () => {
          setCurrentWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      const handleChange = (selectedOption) => {
        setSelectedCurrency(selectedOption);
      };

      const checkLimit = async () => {
        try {
            const response = await fetch('/api/check-code-limit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ couponCode })
            });
    
            const data = await response.json();
    
            if (response.ok) {
                // if (data.message !== 'Coupon code is valid. Limit is under 10.') {
                //     setError('Coupon code limit exceeded');
                //     setDiscounted_percentage(0);
                // }
                if (data.message === 'Coupon code is valid.') {
                  return true;
                } else {
                    setError('Coupon code limit exceeded');
                    return false;
                }
            } else {
                setError(data.message || 'An error occurred while applying the coupon code.');
            }
        } catch (error) {
            setError('An error occurred. Please try again later.');
        }
    };
    

      const handlePayment = async (event) => {
        event.preventDefault();

        let isValidCoupon = false;
        let discountPercentage = 0;

        if(couponCode==='EXPLOREWITHUS20'){
          isValidCoupon = await checkLimit();
          if(isValidCoupon) {
            discountPercentage=0.2;
            setDiscounted_percentage(0.2);
          }
        }
        else if(couponCode==='EXPLOREWITHUS25'){
          isValidCoupon = await checkLimit();
          if(isValidCoupon){ 
            discountPercentage = 0.25;
            setDiscounted_percentage(0.25);
          }
        }
        else if(couponCode===`TILLUBADMOSH90`){
          discountPercentage = 0.9;
          setDiscounted_percentage(0.9);
          setCouponCode('');
        }
        else if(couponCode!==''){
          setError('Wrong Coupoun Code');
        }

        try {
          let amount=10;

          const preferredCurrency = selectedCurrency.value
          console.log(selectedPlan,selectedCurrency.value,selectedPlanPrice);
          if (selectedPlan === 'Fortnight Plan') {
            const amountInUSD = selectedPlanPrice - selectedPlanPrice*discountPercentage;
            if(preferredCurrency!=='USD'){
                const response = await axios.post('/api/processPayment', {
                    amountInUSD,
                    userPreferredCurrency: preferredCurrency,
                });
                amount=parseFloat(response.data.amountInPreferredCurrency.toFixed(2)); ; 
                console.log(amount)
            }
            else{
                amount=parseFloat(amountInUSD.toFixed(2));
            }
          } else if (selectedPlan === 'Quadweek Plan') {
            const amountInUSD = selectedPlanPrice - selectedPlanPrice*discountPercentage;
            if(preferredCurrency!=='USD'){
                const response = await axios.post('/api/processPayment', {
                    amountInUSD,
                    userPreferredCurrency: preferredCurrency,
                });
                amount=parseFloat(response.data.amountInPreferredCurrency.toFixed(1)); ; 
                console.log(amount)
            }
            else{
                amount=parseFloat(amountInUSD.toFixed(1));
            }
          } else {
            purchaseSubscription(selectedPlan);
            return;
          }
          const order = await axios.post('/api/create-order', { amount,preferredCurrency });
          const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
          const touristMail = storedIsLoggedIn? localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
          const options = {
            key: 'rzp_live_DqIp2pXc46hk0k',
            amount: order.data.amount,
            currency: preferredCurrency,
            name: 'ADVENTROZ',
            description: 'Test Transaction',
            image: 'https://adventroz.com/favicon.ico',
            order_id: order.data.id,
            handler: async (response) => {
              const data = {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              };
      
              try {
                const result = await axios.post('/api/verify-signature', data);
                if (result.data.status === 'success') {
                  alert('Payment successful');
                  purchaseSubscription(selectedPlan);
                } else {
                  alert('Payment verification failed');
                }
              } catch (error) {
                alert('Signature verification failed');
              }
            },
            prefill: {
              email: touristMail,
            },
            theme: {
              color: '#F37254',
            },
          };
      
          const rzp = new window.Razorpay(options);
          rzp.open();
        } catch (error) {
          alert('There was an error processing your payment. Please try again later.');
        }
      };

    const purchaseSubscription=async(selectplan)=>{
        if(selectplan==='Free Plan'){
            const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
            const amount=0;
            const plan='Free Plan - 0$/month';
            try{
                const response = await fetch('/api/purchaseSubscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({email,plan,amount,couponCode})
                });
                const data = await response.json();
                if(data.message!=='preferred currency reset successfully'){
                    
                }else{
                    setUserSubscription(plan);
                    
                    localStorage.setItem("subscriptionDetail",plan);
                }
            }catch (error) {
            }
        }
        else if(selectplan==='Fortnight Plan'){
            const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
            const amount=11.99;
            const plan='Fortnight Plan - 11.99$/2-weeks';
            try{
                const response = await fetch('/api/purchaseSubscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({email,plan,amount,couponCode})
                });
                const data = await response.json();
                if(data.message!=='preferred currency reset successfully'){
                    
                }else{
                    setUserSubscription(plan);
                    
                    localStorage.setItem("subscriptionDetail",plan);
                }
            }catch (error) {
                // ('Error saving usrrname:', error);
            }
         }
         else if(selectplan==='Quadweek Plan'){
            const email=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
            const amount=19.99;
            const plan='Quadweek Plan - 19.99$/28-days';
            try{
                const response = await fetch('/api/purchaseSubscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({email,plan,amount,couponCode})
                });
                const data = await response.json();
                if(data.message!=='preferred currency reset successfully'){

                  
                    
                }else{
                    setUserSubscription(plan);
                    
                    localStorage.setItem("subscriptionDetail",plan);
                }
            }catch (error) {
                // ('Error saving usrrname:', error);
            }
     }
    }

    return(
        <section className="subscription-plan-heading">
            <NavBar setIsNewTagsVisible={setIsNewTagsVisible} isNewTagsVisible={isNewTagsVisible}/>
            <div style={{ width:currentWidth>500?'500px':currentWidth>400?'400px':'300px' }}>
                <h2 style={
                    { marginLeft:'0px',
                        marginTop:'100px',
                        fontSize:currentWidth>500?'45px':currentWidth>400?'40px':'30px',
                        textAlign: 'center',
                        fontFamily:"'Roboto', sans-serif"
                        }}>
                    Select Your Best Plan For Your Need
                </h2>
            </div>
            <div
                style={{ width:currentWidth>1024?'1000px':'95%',
                    display:'grid',
                    gridTemplateColumns: currentWidth>1024?'repeat(3, 1fr)':currentWidth>547?'repeat(2,1fr)':'repeat(1,1fr)',
                    gap: '20px',
                }}>
                    {SubscriptionPlans.map((plan, index) => (
                        <div key={index} style={{ border: '2px solid #ccc', padding: '20px', margin: '0px', display:'flex',flexDirection:'column' , alignItems:'center', borderRadius:'10px' }}>
                        <p style={{ color: '#b0b0b0',marginTop:'0px' ,fontSize:'24px', marginBottom:'-30px', fontWeight:'500', fontFamily:"'Roboto', sans-serif" }}>{plan.planName}</p>
                        <h2 style={{ color:'black', marginBottom:'-10px',marginLeft:'-10px' }}>{plan.price}</h2>
                        <ul style={{ width:'100%'}}>
                            {plan.benifits.map((benefit, benefitIndex) => (
                            <React.Fragment key={benefitIndex}
                            style={{
                                marginLeft:'-10px'
                            }}
                            >
                                <li className="custom-bullet" style={{ color:'grey', fontSize:'20px', marginBottom:'13px', marginTop:'15px' }}>{benefit.one}</li>
                                <li className="custom-bullet" style={{ color:'grey', fontSize:'20px', marginBottom:'13px' }}>{benefit.two}</li>
                                <li className="custom-bullet" style={{ color:'grey', fontSize:'20px', marginBottom:'13px' }}>{benefit.three}</li>
                                <li className="custom-bullet" style={{ color:'grey', fontSize:'20px', marginBottom:'13px' }}>{benefit.four}</li>
                                <li className="custom-bullet" style={{ color:'grey', fontSize:'20px', marginBottom:'13px' }}>{benefit.five}</li>
                                <li className="custom-bullet" style={{ color:'grey', fontSize:'20px', marginBottom:'15px' }}>{benefit.six}</li>
                            </React.Fragment>
                            ))}
                        </ul>
                        <button
                            style={{ border:'none',
                                width:'90%',
                                fontSize:'16px',
                                backgroundColor:plan.purchase==='in use'?'white':plan.purchase==='Paused'?'grey':'black',
                                color:plan.purchase==='in use'?'black':'white',
                                border:'2px solid black',
                                borderRadius:'5px',
                                height:'40px',
                                textAlign:'center',
                                fontWeight:'300',
                                cursor: 'pointer',
                            }}
                            // Fortnight Plan - 11.99$/2-weeks

                            disabled={localStorage.getItem('subscriptionDetail')===(plan.planName+' - '+plan.price) || plan.planName==='Free Plan'}

                            onClick={()=>{setShowCurrencyChange(true);setSelectedPlan(plan.planName);setSelectedPrice(plan.price);setPriceInNum(plan.planName)}}
                        >
                            {plan.purchase}
                        </button>
                        </div>
                    ))}
            </div>
            <div style={{width:'100%', fontSize:'16px'}}><FinalPage setIsNewTagsVisible={setIsNewTagsVisible}/></div>
            {showCurrencyChage && (
            <div className='book-tour-operator'>
              <div style={{height:'300px', width:'400px'}} className='popUp-booking-tour-guide'>
                <div onClick={()=>setShowCurrencyChange(false)} className='closeLoginAndSignUpPopUp'>X</div>
                <h2 style={{marginTop:'20px', paddingLeft:'30px'}}>{selectedPlan}</h2>
                <form onSubmit={handlePayment} className="pickup-form">
                <Select style={{containerWidth:'10%'}} type="text" value={selectedCurrency} onChange={handleChange} styles={customStyles} options={currencies} name="address" placeholder="Select Currency"/>
                <input style={{marginBottom:'30px'}} type="text" value={couponCode} onChange={(e)=>setCouponCode(e.target.value)} onPaste={(e)=>setCouponCode(e.clipboardData.getData('text'))} id="fullname" name="fullname" placeholder="Enter Coupon Code To Avail Discount: "/>
                <button type="submit">Proceed To Pay </button>
                                        

                {/* <h1>{touristPlace}</h1>
                <div>
                  <label>
                    Number of People: 
                    <input type="number" min="1" value={numPeople} onChange={(e) => setNumPeople(e.target.value)} required />
                  </label>
                </div>
                <h3>Price -: 10.99$/person</h3>
                <p style={{marginTop:"-10px",fontSize:'10px'}}>*(including tickets, 10 min audio book amd ADVENTROZ Guide)</p>
                <button type="submit">Next &rarr;</button>
                <p className='errorInEmail'>{errorInBookingGuideForm}<br /></p> */}
              </form>
              </div>
            </div>
          )
          }
          {error!=='' && (<ErrorMessage message={error} onClose={()=>{setError('')}}/>)}
        </section>
    )
}

export default SubscriptionPlan;