import React from 'react';
import './TermsAndServices.css'

const RefundAndCancellationPolicy = () => {

    return (
        <div className='termsAndServices'>
            <h3>REFUND & CANCELLATION</h3>
            <h5>1. Cancellation by Customer:</h5>
            <p>
                1.1. Customers may cancel their booking, within 24 hours to get full refund.
                <br></br>
                1.2. In the event of canceling an instantly booked tour guide, a deduction of 20% from the total amount will apply.
                <br></br>  
                1.3. After the specified time frame that is after 24 hours of booking, cancellations will incur a cancellation fee of 20% of the total amount.
                <br></br>
                1.4. To cancel an order, please contact our customer service team at [ contact information given on the last page] with your order details.
                <br></br>
                1.5. There will be no refund policy for purchasing the subscription model of the audiobook.
            </p>
            <h5>2. Cancellation by Us:</h5>
            <p>
                2.1. We reserve the right to cancel any order at our discretion. If we cancel an order, the customer will receive a full refund.
                <br></br>
                2.2. Possible reasons for cancellation by us include but are not limited to product unavailability, payment issues, or suspected fraudulent activity.
            </p>
            <h5>3. Notification of Cancellation:</h5>
            <p>
                3.1.Customers will be notified of the cancellation via email or phone, along with details regarding the refund process3.2. Refunds for cancelled orders will be processed within 7-10 business days.
                <br></br>
                3.2.Refunds for cancelled orders will be processed within  7-10 business days.
            </p>
            <h5>4. Amendments to the Cancellation Policy:</h5>
            <p>
                4.1.We reserve the right to amend this Cancellation Policy at any time. Changes will be posted on this page and will take effect immediately. It is your responsibility to review this policy periodically.
            </p>
            <h5>5. Contact Information:</h5>
            <p>
                5.1. For any questions related to cancellations, please contact us at
                <br></br>
                [ Email-contact@adventroz.com ].
            </p>
            <h2>PAYMENTS TERMS</h2>
            <h4>1. Accepted Methods:</h4>
            <p>
            Payments can be made via credit/debit cards, bank transfers, and specified online payment systems.
            </p>
            <h4>2. Pricing:</h4>
            <p>
            Prices are listed on the website and are subject to change without notice. The price at the time of booking is final.
            </p>
            <h4>3. Billing Information:</h4>
            <p>
            Provide accurate billing details. Update your information promptly to ensure successful transactions.
            </p>
            <h4>4. Payment Timing:</h4>
            <p>
            Payment is required at the time of booking. We reserve the right to cancel or refuse bookings if payment is not received.
            </p>
            <h4>5. Taxes:</h4>
            <p>
                You are responsible for any applicable local, state, or national taxes related to your booking.
            </p>
            <h4>6. Invoices:</h4>
            <p>
            Invoices are available upon request and will be sent to the email address provided.
            </p>
            <h4>7. Refunds and Cancellations:</h4>
            <p>
            Refer to our refund and cancellation policy on the website for details.
            </p>
            <h4>8. Late Payments:</h4>
            <p>
            Late payments may incur interest. We may suspend or cancel bookings until full payment is received.
            </p>
            <h4>9. Disputed Charges:</h4>
            <p>
            Disputes must be reported within 30 days of the charge. We will review and rectify any valid issues.

            </p>
            <h4>10. Payment Security:</h4>
            <p>
            We take measures to protect your payment information but are not liable for unauthorized access. Use secure methods to transmit payment details.
            </p>
            <p>
                By booking a Service on our website, you agree to these payment terms.
            </p>
            <h2>CONTACT INFO.</h2>
            <p>Email id - contact@adventroz.com</p>
            <p>Address:  H no. A-57, Street no. 3, Churriya Mohalla, Tughalakabad Village, New Delhi -110044</p>
        </div>
    );
};

export default RefundAndCancellationPolicy;
