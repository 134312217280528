// src/components/Popup.js
import React from 'react';
import './Popup.css';
const Popup = ({ closePopup }) => {
  return (
    <div className="popupOverlay">
      <div className="popupContent">
        <button onClick={closePopup}>X</button>
      </div>
    </div>
  );
};

export default Popup;
