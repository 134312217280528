import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsAndServices.css'

const TermsAndCondition = () => {

    const navigate = useNavigate();

    const navigateToWebsite=(event)=>{
        event.preventDefault();
        navigate({
            pathname: "/",
        });
      }
    
      const navigateToPrivacyPolicy=(event)=>{
        event.preventDefault();
        navigate({
            pathname: "/Privacy_Policy",
        });
      }
      

    return (
        <div className='termsAndServices'>
            <h1>Terms and Conditions</h1>
            <h2>CONTRACT</h2>
            <p>
                The travel site <span style={{color:'blue'}} onClick={navigateToWebsite}>www.ADVENTROZ.com</span>,
                site and Smartphone App Platforms like iOS and Android are maintained by ADVENTROZ(sole proprietorship).
                By accessing or using this site or using any of its services, you are agreed on the terms and conditions 
                mentioned below, including any supplementary guidelines and future modifications. Also, read our privacy 
                <span style={{color:'blue'}} onClick={navigateToPrivacyPolicy}>www.ADVENTROZ.com/PrivacyPolicy</span>
            </p>
            <p>
                ADVENTROZ may change, alter or remove any part of these below-written Terms and Conditions without any prior 
                notice. Any changes done in this Terms and Conditions of Use will be applicable the moment they are posted on 
                the site. You need to re-check the "Terms & Conditions" link to be fully aware of the entire scenario.
            </p>
            <p>
            The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction 
            or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration 
            requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations 
            do so on their initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable
            </p>
            <p>
                1st Choice: The Site is intended for users who are at least 18 years old. Persons under the age of 13 
                are not permitted to register for the Site.
            </p>
            <p>
                2nd Choice: All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, 
                and be directly supervised by, their parent or guardian to use the Site. If you are a minor, you must 
                have your parent or guardian read and agree to these Terms of Use prior to you using the Site.
            </p>
            <h2>LIMITED LIABILITY</h2>
            <p>
            Unless ADVENTROZ explicitly acts as a reseller in certain scenarios, ADVENTROZ always acts as a facilitator by connecting the User with the 
            respective service providers like Tour Guides, Content/Information regarding places etc. (collectively referred to as "Service Providers"). 
            ADVENTROZ's liability is limited to providing the User with a confirmed booking as selected by the User.
            </p>
            <p>
            Any issues or concerns faced by the User at the time of availing any such services shall be the sole responsibility of the Service Provider. 
            ADVENTROZ will have no liability for the acts, omissions, errors, representations, warranties, breaches or negligence on the part of any Service Provider.
            </p>
            <p>
            Unless explicitly committed by ADVENTROZ as a part of any product or service:
            </p>
            <ul>
                <li>
                ADVENTROZ assumes no liability for the standard of services as provided by the respective Service Providers.
                </li>
                <li>
                ADVENTROZ provides no guarantee about their quality or fitness as represented
                </li>
                <li>
                ADVENTROZ doesn't guarantee the availability of any services as listed by a Service Provider.
                </li>
            </ul>
            <p>
                By making a booking, the  User understands ADVENTROZ merely provides a technology platform for booking 
                of services and products and the ultimate liability rests on the respective Service Provider and not ADVENTROZ. 
                Thus the ultimate contract of service is between User and Service Provider.
            </p>
            <p>
                The user further understands that the information displayed on the website concerning any service is displayed as 
                furnished by the Service Provider. ADVENTROZ, therefore cannot be held liable in case the information provided by 
                the Service Provider is found to be inaccurate, inadequate obsolete or in contravention of any laws, rules, regulations
                or directions in force.
            </p>
            <h2>COPYRIGHT & TRADEMARK</h2>
            <p>
                Products, services and content displayed on ADVENTROZ are the properties of this website. Any unauthorized copy & use of 
                the trademark product & services without prior concern will be taken as larceny and strict actions may be taken against the
                 concerned person/organization/company.
            </p>
            <p>
            ADVENTROZ gives you a limited right to enter, explore, use and transact on its site. You agree not to misuse its materials and 
            interrupt the operation of this Site in any way. You understand that except for information, products or services supplied by ADVENTROZ 
            do not endorse any other information in any way. You also understand that ADVENTROZ does not assure that files available for downloading 
            through the site will be free from any type of virus.
            </p>
            <h2>USER AGREEMENT</h2>
            <h3>USER RESPONSIBILITY</h3>
            <p>
            By accessing and using the Site, you affirm that: [(1) the information you provide during registration is truthful, accurate, current, and 
            complete; (2) you will promptly update any changes to this information;] (3) you possess the legal capacity to agree to these Terms of Use; 
            [(4) you are at least 13 years old;] (5) you are not a minor in your jurisdiction[, or if you are, you have obtained parental consent to use 
            the Site]; (6) you will not employ automated or non-human methods to access the Site; (7) you will not engage in illegal or unauthorized activities 
            on the Site; and (8) your use of the Site complies with all applicable laws and regulations
            </p>
            <h3>USER REGISTRATION</h3>
            <p>
                “Registration with the Site may be necessary. By doing so, you commit to maintaining the confidentiality of your password and accept responsibility 
                for any activity associated with your account. You should not share your OTP and password with anyone; you will be solely responsible for maintaining the
                confidentiality of your account. We retain the right to modify, reclaim, or alter any username that we deem inappropriate, offensive, or objectionable.
            </p>
            <h3>REFUND & CANCELLATION</h3>
            <h5>1. Cancellation by Customer:</h5>
            <p>
                1.1. Customers may cancel their booking, within 24 hours to get full refund.
                <br></br>
                1.2. In the event of canceling an instantly booked tour guide, a deduction of 20% from the total amount will apply.
                <br></br>  
                1.3. After the specified time frame that is after 24 hours of booking, cancellations will incur a cancellation fee of 20% of the total amount.
                <br></br>
                1.4. To cancel an order, please contact our customer service team at [ contact information given on the last page] with your order details.
                <br></br>
                1.5. There will be no refund policy for purchasing the subscription model of the audiobook.
            </p>
            <h5>2. Cancellation by Us:</h5>
            <p>
                2.1. We reserve the right to cancel any order at our discretion. If we cancel an order, the customer will receive a full refund.
                <br></br>
                2.2. Possible reasons for cancellation by us include but are not limited to product unavailability, payment issues, or suspected fraudulent activity.
            </p>
            <h5>3. Notification of Cancellation:</h5>
            <p>
                3.1.Customers will be notified of the cancellation via email or phone, along with details regarding the refund process3.2. Refunds for cancelled orders will be processed within 7-10 business days.
                <br></br>
                3.2.Refunds for cancelled orders will be processed within  7-10 business days.
            </p>
            <h5>4. Amendments to the Cancellation Policy:</h5>
            <p>
                4.1.We reserve the right to amend this Cancellation Policy at any time. Changes will be posted on this page and will take effect immediately. It is your responsibility to review this policy periodically.
            </p>
            <h5>5. Contact Information:</h5>
            <p>
                5.1. For any questions related to cancellations, please contact us at
                <br></br>
                [ Email-contact@adventroz.com ].
            </p>
            <h2>PAYMENTS TERMS</h2>
            <h4>1. Accepted Methods:</h4>
            <p>
            Payments can be made via credit/debit cards, bank transfers, and specified online payment systems.
            </p>
            <h4>2. Pricing:</h4>
            <p>
            Prices are listed on the website and are subject to change without notice. The price at the time of booking is final.
            </p>
            <h4>3. Billing Information:</h4>
            <p>
            Provide accurate billing details. Update your information promptly to ensure successful transactions.
            </p>
            <h4>4. Payment Timing:</h4>
            <p>
            Payment is required at the time of booking. We reserve the right to cancel or refuse bookings if payment is not received.
            </p>
            <h4>5. Taxes:</h4>
            <p>
                You are responsible for any applicable local, state, or national taxes related to your booking.
            </p>
            <h4>6. Invoices:</h4>
            <p>
            Invoices are available upon request and will be sent to the email address provided.
            </p>
            <h4>7. Refunds and Cancellations:</h4>
            <p>
            Refer to our refund and cancellation policy on the website for details.
            </p>
            <h4>8. Late Payments:</h4>
            <p>
            Late payments may incur interest. We may suspend or cancel bookings until full payment is received.
            </p>
            <h4>9. Disputed Charges:</h4>
            <p>
            Disputes must be reported within 30 days of the charge. We will review and rectify any valid issues.

            </p>
            <h4>10. Payment Security:</h4>
            <p>
            We take measures to protect your payment information but are not liable for unauthorized access. Use secure methods to transmit payment details.
            </p>
            <p>
                By booking a Service on our website, you agree to these payment terms
            </p>
            <h2>THIRD-PARTY ADVERTISEMENTS AND CONTENT DISCLAIMER.</h2>
            <h3>1. Advertisements:</h3>
            <p>
            Our website may display advertisements from third parties. These advertisements are not under our control, and we are not responsible for the content, accuracy, products, or services offered in these advertisements.
            </p>
            <h3>2. Third-Party Content:</h3>
            <p>
                Our website may include links to third-party websites or content. These links are provided for your convenience and do not imply endorsement or approval of the linked content. We are not responsible for the content, accuracy, legality, or any other aspect of these third-party sites.
            </p>
            <h3>3. User Responsibility:</h3>
            <p>
            You acknowledge and agree that any dealings you have with third parties found on or through our website are solely between you and the third party. We are not liable for any loss or damage incurred as a result of your interactions with third parties.
            </p>
            <h3>4. Disclaimer of Liability:</h3>
            <p>
            We do not endorse or assume any responsibility for any third-party products, services, or content. You use third-party content at your own risk, and you should review the terms and conditions and privacy policies of these third parties.
            </p>
            <p>By using our website, you agree that we are not responsible for third-party advertisements or content.</p>
            <h2>DISCLAIMERS</h2>
            <h3>1. General Information Disclaimer:</h3>
            <p>
            The information provided on this website is for general informational purposes only. While we strive to keep the information up-to-date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability concerning the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
            </p>
            <h3>2. Booking and Reservation Disclaimer:</h3>
            <p>
            Our website facilitates bookings and reservations for guides and services. We act solely as an intermediary between you and the service providers. Therefore, we cannot be held responsible for any act, omission, error, injury, loss, delay, or damage caused by the guide or service provider.
            </p>
            <h3>3. Accuracy of Information:</h3>
            <p>
            We strive to ensure that all information provided on our website is accurate and reliable. However, we do not guarantee the accuracy, completeness, or timeliness of any information or content on the website. The details of guides, including availability, services offered, and pricing, may change without notice.
            </p>
            <h3>4. Limitation of Liability:</h3>
            <p>
            In no event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, loss of data, or other intangible losses arising out of or in connection with your use of the website or the services provided by the guides.
            </p>
            <h3>5. Third-Party Links and Services:</h3>
            <p>
            Our website may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.

            </p>
            <h3>6. Indemnification:</h3>
            <p>
            You agree to indemnify and hold harmless our company, its affiliates, officers, directors, employees, agents, and partners from and against any claims, liabilities, damages, losses, or expenses, including legal fees and costs, arising out of or in any way connected with your use of our website, services, or booking of guides.
            </p>
            <h3>7. Modification of Terms:</h3>
            <p>
            We reserve the right to modify or replace these disclaimers and terms at any time. It is your responsibility to check this page periodically for changes. Your continued use of the website after any such modifications constitutes acceptance of the new terms.
            </p>
            <p>
            By using our website and booking guides through our platform, you acknowledge that you have read, understood, and agree to these disclaimers and terms. If you do not agree with any part of these terms, please do not use our website or services.
            </p>
            <h2>CONTACT INFO.</h2>
            <p>Email id - contact@adventroz.com</p>
            <p>Address:  H no. A-57, Street no. 3, Churriya Mohalla, Tughalakabad Village, New Delhi -110044</p>
        </div>
    );
};

export default TermsAndCondition;
