import React,{useState} from 'react'
import './TourGuideEarnings.css';

function TourGuideEarnings({dashboardOpener}) {
    const [earnings,setEarnings]=useState([]);

  return (
    <section id='earnings-for-tour-guide'>
      <div className='tour-guide-top-nav-bar'>
      <button 
                style={{marginTop:'20px'}}
                onClick={dashboardOpener} 
                className='tour-operator-dashboard-opener'
            >&#9776;</button>
      </div>
      <div id='review-profile-page'>
        <div className='profile-tour-guide'>
            <div className='profile-view-in-tour-guide'>
            {localStorage.getItem('profilePicture') ? (
            <img src={localStorage.getItem('profilePicture')} style={{height:'100%', width:'100%', objectFit:'cover'}} alt="Profile" className="profilePicture" />
            ) :(
            <svg xmlns="http://www.w3.org/2000/svg"  height="100" fill="white" className="bi bi-person-fill" viewBox="0 -2 17 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
            </svg>
            )}
            </div>
            {/* color:'rgb(202, 53, 190) */}
            <div className='text-section'>
                <h3 style={{color:'black', marginBottom:'-10px', fontSize:'24px', marginTop:'15px'}}>{localStorage.getItem('nameOfTheTourist')?`${localStorage.getItem('nameOfTheTourist')}`:`User`}</h3>
                <p style={{color:'black', marginBottom:'-10px', fontSize:'14px'}}>{localStorage.getItem('status')?`${localStorage.getItem('status')}`:'N/A'}</p>
                <p style={{color:'black', marginBottom:'-10px', fontSize:'14px'}}>Total Earnings : {`0 $(USD)`}</p>
            </div>
            <div className='total-trips'>
                Number of Trips: 0
            </div>
        </div>
        <div className='left-margin' style={{marginTop: '40px', display:'flex', alignItems:'center', gap:'10px'}}>
            <div style={{border:'1px solid grey', backgroundColor:'grey', width:'5%', height:'0px'}}></div>
            <h3 style={{color:'grey'}}>Earnings</h3>
            <div style={{border:'1px solid grey', backgroundColor:'silver', width:'70%', height:'0px'}}></div>
        </div>
        {earnings.length === 0 && (<div style={{display:'flex',alignItems:'center',justifyContent:"center", width:'83%'}}>
                <p>No earnings yet</p>
        </div>)}
        </div>
    </section>
  )
}

export default TourGuideEarnings
