import React, { useState, useEffect } from 'react'
import ErrorMessage from '../../HomePage/ErrorMessage';
import './TourGuideEditProfile.css'

function TourGuideEditProfile({dashboardOpener}) {

  const userEmailAddress=useState((localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle'));

  const emailAddress=useState(userEmailAddress[0]);

  const [useEffectCalled,SetUseEffectCalled]=useState(0);
  const [profilePicture,setProfilePicture]=useState(null);

  // const [touristPlaces,setTouristPlaces]=useState([]);
  // const [selectedPlaces, setSelectedPlaces] = useState([]);

  const [errorMessage,setErrorMessage]=useState('');

  const handleDivClick = () => {
    const fileInput = document.getElementById('tourGuideProfilePicture');
    if (fileInput) {
        fileInput.click();
    }
};

  const [formData, setFormData] = useState({
    profilePicture:null,
    firstName: '',
    lastName: '',
    dob: '',
    countryCode:'+91',
    phoneNumber: '',
    address: '',
    guideCity: '',
    guidePlaces: '',
    guidePrice: '',
    status: '',
    fileUrl: '',
    officialDocumentName:'',
    officialDocument:null,
    emailAddress:userEmailAddress,
    verificationStatus:'Send For verification',
  });

  // useEffect(()=>{
  //   function formatDate(dateString) {
  //     const date = new Date(dateString);
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, '0'); 
  //     const day = String(date.getDate()).padStart(2, '0');
  //     return `${year}-${month}-${day}`;
  //   }

  //   const documentBlob = new Blob([formData.officialDocument], { type: 'application/pdf' });

  //   setFormData({ ...formData, dob:formatDate(formData.dob), fileUrl:URL.createObjectURL(documentBlob)});


  //   const profilePictureBlob = new Blob([formData.profilePicture], { type: 'image/jpeg' });
  //   setFormData({ ...formData, dob:formatDate(formData.dob), profilePicture:URL.createObjectURL(profilePictureBlob)});

  //   //   const profilePictureBlob = new Blob([data.profilePicture], { type: 'image/jpeg' }); // Adjust type as needed
  //   //   setProfilePictureUrl(URL.createObjectURL(profilePictureBlob));



  //   // if (data.profilePicture) {
  //   //   const profilePictureBlob = new Blob([data.profilePicture], { type: 'image/jpeg' }); // Adjust type as needed
  //   //   setProfilePictureUrl(URL.createObjectURL(profilePictureBlob));
  //   // }
    
  //   // if (data.officialDocument) {
  //   //   const documentBlob = new Blob([data.officialDocument], { type: 'application/pdf' }); // Adjust type as needed
  //   //   setDocumentUrl(URL.createObjectURL(documentBlob));
  //   // }



    
  // },[formData]);


  useEffect(() => {

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    function setDocumentUrl(file){
      const reader = new FileReader();
      const fileUrl = URL.createObjectURL(file);
      reader.onloadend = () => {
        setFormData({ 
          ...formData, 
          fileUrl:fileUrl
        });
      };
      reader.readAsArrayBuffer(file);
    }
    

    const fetchGuideData = async () => {
      try {
        const response = await fetch(`/api/guides/${userEmailAddress[0]}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFormData(prevData => ({
          profilePicture: `${data.profilePicture}` || prevData.profilePicture,
          firstName: data.firstName || prevData.firstName,
          lastName: data.lastName || prevData.lastName,
          dob: formatDate(data.dob) || prevData.dob,
          countryCode: data.countryCode || prevData.countryCode,
          phoneNumber: data.phoneNumber || prevData.phoneNumber,
          address: data.address || prevData.address,
          guideCity: data.guideCity || prevData.guideCity,
          guidePlaces: data.guidePlaces || prevData.guidePlaces,
          guidePrice: data.guidePrice || prevData.guidePrice,
          status: data.status || prevData.status,
          officialDocumentName: data.officialDocumentName || prevData.officialDocumentName,
          officialDocument: `${data.officialDocument}` || prevData.officialDocument,
          emailAddress: data.emailAddress || prevData.emailAddress,
          verificationStatus: data.verificationStatus || prevData.verificationStatus
        }));

        setProfilePicture(`${data.profilePicture}`);
        localStorage.setItem('nameOfTheTourist',`${data.firstName} ${data.lastName}`);
        localStorage.setItem('status',data.status);
        localStorage.setItem('profilePicture',data.profilePicture);
  
      } catch (error) {
      }
    };
    if(useEffectCalled===0){
      fetchGuideData();
      SetUseEffectCalled(1);
    }
  },[userEmailAddress,useEffectCalled]);

  // const fetchTouristDestination = async () => {
  //   const cityName=formData.guideCity;
  //       try{
  //         setTouristPlaces([]);
  //           const response = await fetch('/api/namesOfTouristPlaces', {
  //               method: 'POST',
  //               headers: {
  //                   'Content-Type': 'application/json'
  //               },
  //               body: JSON.stringify({cityName})
  //           });
  //           const data = await response.json();
  //           setTouristPlaces(data);
  //         } catch (error) {
  //           setErrorMessage('something went wrong');
  //         }
  //     }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      const fileUrl = URL.createObjectURL(file);
      reader.onloadend = () => {
        const binaryData = reader.result;
        setFormData({ 
          ...formData, 
          officialDocumentName: file.name, 
          officialDocument: file,
          fileUrl:fileUrl
        });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, profilePicture:file});
        const reader = new FileReader();
        reader.onload = (e) => {
            setProfilePicture(e.target.result);
        };
        reader.readAsDataURL(file);
    }
  };

  // const openDocument = () => {
  //   // if (formData.fileUrl) {
  //   //   window.open(formData.fileUrl, '_blank');
  //   // } 
  //   if (formData.officialDocument) {
  //     const reader = new FileReader();
  //     const fileUrl = URL.createObjectURL(formData.officialDocument);
  //     reader.onloadend = () => {
  //       setFormData({ 
  //         ...formData, 
  //         fileUrl:fileUrl
  //       });
  //     };
  //     window.open(formData.fileUrl, '_blank');
  //   }
  //   else {
  //     setErrorMessage('No document selected');
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFormComplete = Object.entries(formData).every(([key, value]) => {
      console.log(key,value);
        if (key === 'profilePicture' || key==='officialDocument' || key==='lastName') {
            if(key==='profilePicture' || key==='officialDocument'){
              if(value===null){
                setErrorMessage('profile picture is necessary');
                return false;
              }
              return true;
            }
            return true;
        }
        return value !== '';
    });

    if (isFormComplete) {
      
        const formDataToSend = new FormData();
        
        // Append all form fields
        console.log(userEmailAddress);
        const email=userEmailAddress[0];
        formDataToSend.append('profilePicture', formData.profilePicture);
        formDataToSend.append('firstName', formData.firstName);
        formDataToSend.append('lastName', formData.lastName);
        formDataToSend.append('dob', formData.dob);
        formDataToSend.append('countryCode', formData.countryCode);
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('guideCity', formData.guideCity);
        formDataToSend.append('guidePlaces', formData.guidePlaces);
        formDataToSend.append('guidePrice', formData.guidePrice);
        formDataToSend.append('verificationStatus', "Under Verification");
        formDataToSend.append('status',formData.status);
        formDataToSend.append('emailAddress', email);
        formDataToSend.append('officialDocumentName', formData.officialDocumentName);
        formDataToSend.append('officialDocument', formData.officialDocument);
        
        try {
          const response = await fetch('/api/your-api-endpoint', {
            method: 'POST',
            body: formDataToSend,
          });
      
          if (response.ok) {
            console.log('Form data uploaded successfully');
            setFormData({ ...formData, verificationStatus:'Under Verification'});
          } else {
            // setErrorMessage('profile photo size is too large.');
          }
        } catch (error) {
            setErrorMessage('Error:', error);
        }
      
    } else {
        if(formData.profilePicture!=null){
          setErrorMessage('Please fill in all required fields.');
        }
    }
};



  return (
    <section id='edit-tour-guide-profile-page'>
      <div className='top-background-tour-guide-edit-section'>
      <button 
      onClick={dashboardOpener} 
        className='tour-operator-dashboard-opener'
      >&#9776;</button>
      </div>
      <div id='edit-profie-section'>
        <div id='tour-guide-profile-section-profile-picture-section'>
          <div className='tour-guide-profile-picture' onClick={handleDivClick}>
          {profilePicture ? (
            <img src={profilePicture} style={{height:'100%', width:'100%', objectFit:'cover'}} alt="Profile" className="profilePicture" />
            ) :(
            <svg xmlns="http://www.w3.org/2000/svg"  height="150" fill="white" className="bi bi-person-fill" viewBox="0.2 -2 15 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
            </svg>
            )}
          </div>
          <input type="file" id="tourGuideProfilePicture" accept="image/*" style={{display:'none'}} onChange={handleProfilePictureChange}/>

          <div>
            <p style={{marginBottom:'-15px', marginTop:'10px', fontSize:'20px',fontWeight:'600'}}>{formData.firstName!==''?`${formData.firstName} ${formData.lastName}`:'User'}</p>
            <p style={{marginBottom:'-15px'}}>{formData.emailAddress}</p>
            <p>{formData.status}</p>
          </div>
        </div>
        <form onSubmit={handleSubmit} id='tour-guide-profile-setup'>
          <h2 style={{color:'lightGray'}}>Profile Set Up</h2>
           <div>
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} />
          </div>
          <div>
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange}/>
          </div>
          <div>
            <label htmlFor="dob">Date of Birth</label>
            <input type="date" id="dob" name="dob" value={formData.dob} onChange={handleChange} />
          </div>
          <div>
            <label htmlFor="phoneNumber">Phone Number</label>
            <div style={{display:'grid', gridTemplateColumns:'1fr 3 fr'}} className="phone-input-container">
              <select style={{width:'70px', paddingLeft:'0px'}} id="countryCode" name="countryCode" value={formData.countryCode} onChange={handleChange} >
                <option value="+91">+91</option>
                <option value="+44">+44</option>
                <option value="+977">+977</option>
                <option value="+1">+1</option>
              </select>
              <input style={{marginLeft:'-10px', width:'220px'}} type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} maxLength="10" keyboardType="numeric" pattern="\d*" onChange={handleChange}/>
            </div>
          </div>
          <div>
            <label htmlFor="address">Address</label>
            <textarea id="address" name="address" value={formData.address} onChange={handleChange} />
          </div>

          <div>
            <label htmlFor="guidePlaces">City You Can Guide</label>
            <select id="state" name="guideCity" value={formData.guideCity} onChange={handleChange} >
                <option value="" disabled selected>Select the city you can guide</option>
                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Ladakh">Ladakh</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
            </select>

          </div>

          <div>
            <label htmlFor="guidePlaces">Places You Can Guide</label>
            <input type="text" id="guidePlaces" name="guidePlaces" placeholder='seperate them with comma(,)' value={formData.guidePlaces} onChange={handleChange}/>
          </div>
          <div>
            <label htmlFor="guidePrice">Guiding Price (in USD)</label>
            <input type="number" id="guidePrice" name="guidePrice" value={formData.guidePrice} onChange={handleChange} />
          </div>
          <div>
            <label htmlFor="status">Professional Status</label>
            <select id="status" name="status" value={formData.status} onChange={handleChange}>
              <option value="" disabled selected>Select the professional status</option>
              <option value="professional">Professional</option>
              <option value="freelancer">Freelancer</option>
            </select>
          </div>
          <div>
          <label htmlFor="status">Professional Document</label>
            <div style={{display:'grid', gridTemplateColumns:'1.2fr  1fr'}} className="phone-input-container">
              <span style={{width:'250px', overflow:'hidden',marginRight:'-10px'}} className="file-upload-name">{formData.officialDocumentName===''?'Official Document':formData.officialDocumentName}</span>
              <div className='add-a-document' onClick={() => document.getElementById('document').click()} style={{width:'40px', height:'40px', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:"center"}}>
              <svg xmlns="http://www.w3.org/2000/svg" height="20" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
              </svg>
              </div>
              <input type="file" id="document" name="document" accept="application/pdf" onChange={(e)=>{handleFileChange(e)}} style={{display:'none'}}/>
            </div>
          </div>
          <div style={{ marginTop: '20px', gridColumn: '1 / -1', alignItems:"center", width:'90%', height:'80px'}}>
            <button type="submit" style={{ marginTop: '20px', gridColumn: '1 / -1', fontSize:"20px", border:"none", backgroundColor:formData.verificationStatus==='Send For verification'?"black":'grey', color:'white', borderRadius:'10px', border:'1px solid black', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'15px', paddingRight:'15px'}}>{formData.verificationStatus}</button>
          </div>
        </form>
      </div>
      {errorMessage!=='' && (<ErrorMessage message={errorMessage} onClose={()=>(setErrorMessage(''))}/>)}
    </section>
  )
}

export default TourGuideEditProfile
