import React,{useState} from 'react';
import FinalPage from './HomePage/finalPage';
import NavBar from './NavBar';
import ExploreCitiesMore from './CitiesDesc';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ExploreBasedOnExploreClick = () => {
    const query = useQuery();
    const selectedCountryName=query.get('country') || 'No country specified';
    const selectedCityName=query.get('city') || 'No city specified';
    const touristPlace=query.get('touristPlace') || 'no tourist place selected'; 

    const [isNewTagsVisible,setIsNewTagsVisible]=useState(false);

    return (
        <div>
            <div id="home"><NavBar isNewTagsVisible={isNewTagsVisible} setIsNewTagsVisible={setIsNewTagsVisible}/></div>
            <div id="destination"><ExploreCitiesMore setIsNewTagsVisible={setIsNewTagsVisible} selectedCountryName={selectedCountryName} selectedCityName={selectedCityName} touristPlace={touristPlace}/></div>
            <div id="contact"><FinalPage setIsNewTagsVisible={setIsNewTagsVisible}/></div>
        </div>
    );
};

export default ExploreBasedOnExploreClick;
