import React,{useState, useEffect} from 'react'

function TourGuideReviewsSection({onClose,selectedGuide}) {
    const [averageRating,setAverageRating]=useState(0);
    const [reviews, setReviews] = useState([]);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setScreenWidth(window.innerWidth);

      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    const renderStars = (rating) => {
        const totalStars = 5;
        const filledStars = '★'.repeat(rating);
        const emptyStars = '☆'.repeat(totalStars - rating);
        return filledStars + emptyStars;
      };

      useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await fetch(`/api/reviews/${selectedGuide.emailAddress}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setReviews(data);

            } catch (error) {
                
            }
        };

        fetchReviews();
    }, []);

    useEffect(()=>{
        if(reviews.length===0){
            setAverageRating(0);
            return;
        }
        const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
        const average =  Math.round(totalRating / reviews.length);
        setAverageRating(average);
        localStorage.setItem('averageReviews',average)
    },[reviews,averageRating])

  return (
    <div className='book-tour-operator'>
        <div className='popUp-booking-tour-guide'>
            <div onClick={onClose} className='closeLoginAndSignUpPopUp'>X</div>
            <div style={{width:screenWidth<500?'250px':'300px', height:'120px', marginLeft:"0px"}} className='profile-tour-guide'>
            <div style={{width:'70px', height:'70px'}} className='profile-view-in-tour-guide'>
            {selectedGuide.profilePicture ? (
            <img src={selectedGuide.profilePicture} style={{height:'100%', width:'100%', objectFit:'cover'}} alt="Profile" className="profilePicture" />
            ) :(
            <svg xmlns="http://www.w3.org/2000/svg"  height="100" fill="white" className="bi bi-person-fill" viewBox="0 -2 17 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
            </svg>
            )}
            </div>
            <div className='text-section'>
                <h3 style={{color:'black', marginBottom:'-10px', fontSize:'17px', marginTop:'0px'}}>{(selectedGuide.firstName!=='' || selectedGuide.firstName!==null)?`${selectedGuide.firstName} ${selectedGuide.lastName}`:`User`}</h3>
                <p style={{color:'black', marginBottom:'-15px', fontSize:'13px'}}>Professional</p>
                <p style={{color:'black', marginBottom:'-5px', fontSize:'13px'}}>Average Rating : <span className='rating-stars'>{renderStars(averageRating)}</span></p>
            </div>
            <div className='total-trips'>
                Number of Trips: 0
            </div>
        </div>
        <div style={{marginTop: '5px', display:'flex', alignItems:'center', gap:'10px'}}>
            <div style={{border:'1px solid grey', backgroundColor:'grey', width:'5%', height:'0px'}}></div>
            <h3 style={{color:'grey'}}>Reviews</h3>
            <div style={{border:'1px solid grey', backgroundColor:'silver', width:'70%', height:'0px'}}></div>
        </div>
        <div style={{gridTemplateColumns:screenWidth<500?'1fr':'1fr 1fr', width:'95%', overflowY:'auto',height: '300px', marginLeft:'0px'}} id='reviews-box'>
            {/* {reviews.length === 0 && (<div style={{display:'flex',alignItems:'center',justifyContent:"center", width:'83%'}}><p>No reviews yet</p></div>)} */}
            {reviews.map((review, index) => (
                <div key={index} className="review-card">
                    <div style={{display:'flex',alignItems:'center', gap:'20px'}}>
                    <div style={{height:'30px', width:'30px'}} className='profile-view-in-tour-guide'>
                        {review.profilePicture ? (
                        <img src={review.profilePicture} style={{height:'100%', width:'100%', objectFit:'cover'}} alt="Profile" className="profilePicture" />
                        ) :(
                        <svg xmlns="http://www.w3.org/2000/svg"  height="30" fill="white" className="bi bi-person-fill" viewBox="0 -2 17 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg>
                        )}
                    </div>
                        <h3 style={{color:'rgb(202, 53, 190)', fontSize:'16px'}}>{review.name}</h3>
                    </div>
                    <p style={{marginTop:'0px', marginBottom:'-13px', fontSize:'14px'}}>{review.review}</p>
                    <p style={{ fontSize:'14px'}} className="rating">Rating: <span className='rating-stars'>{renderStars(review.rating)}</span></p>
                </div>
            ))}
            </div>
        </div>
    </div>
  )
}

export default TourGuideReviewsSection
