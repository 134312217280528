import React from 'react';
import './PrivacyPolicy.css'

const ContactUs = () => {
    return (
        <div className='termsAndServices'>
            <h1>Contact Us</h1>
            <p>We'd love to hear from you! Whether you have questions about our tour guide services,
            audiobook subscriptions, or any other inquiries, our team is here to help.</p>
            <br></br>
            <p>You may contact us using the information below:</p>
            <p>Merchant Legal entity name: NIZAAM KHAN</p>
            <p>Registered Address: 42/3, Churriya Mohalla, TKD Village, New Delhi, Delhi, PIN: 110044</p>
            <p>Operational Address: 42/3, Churriya Mohalla, TKD Village, New Delhi, Delhi, PIN: 110044</p>
            <p>Telephone No: 9953072372</p>
            <p>E-Mail ID: contact@adventroz.com</p>
            <br></br>
            <p>Thank you for choosing ADVENTROZ. We look forward to assisting you!</p>
        </div>
    );
};

export default ContactUs;
