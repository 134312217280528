import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import './touristDestination.css';

function ExploreDestinations({ selectedCountryName, selectedCityName }) {
  const [touristPlaces, setTouristPlaces] = useState([]);
  const [selectedTouristPlace,setSlelectedTouristPlace]=useState(null);
  const [touristPlacesNames,setTouristPlacesNames]=useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTouristDestination = async () => {
        try{
            const response = await fetch('/api/touristPlaces', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({selectedCountryName,selectedCityName})
            });
            const data = await response.json();
            setTouristPlaces(data);
            const names = data.map(place => {
              if (place.imageUrl !== null) {
                  return { value: place.id, label: place.name };
              } else {
                  return null;
              }
          }).filter(Boolean);
            setTouristPlacesNames(names);

        } catch (error) {
          //error handling
            //('Error in getting data:', error);
        }
    };
    if(selectedCountryName==='No country specified' || selectedCityName==='No city specified'){
      //error handling
      //("somethingwent wrong");
    }
    else{
      fetchTouristDestination();
    }
  }, [selectedCountryName,selectedCityName]);

  const handleTouristPlaceChange=(selectedOption)=>{
    setSlelectedTouristPlace(selectedOption);
  }
  const handleExploreClick = async (country,city,touristPlace,imageUrl) => {
    localStorage.setItem('touristPlaceData',imageUrl);
    navigate(`/Explore?country=${encodeURIComponent(country)}&city=${encodeURIComponent(city)}&touristPlace=${encodeURIComponent(touristPlace)}`);
  };
  const truncateName = (name) => {
    const cleanName = name.replace(/\(.*?\)/g, '').split(',')[0];
    return cleanName.length > 24 ? `${cleanName.substring(0, 24)}...` : cleanName;
  };

  return (
    <div className='touristDestinationPage'>
        <div className='inputCountry' style={{ width: '500px' }}>
            <Select 
                placeholder="Select Tourist Place"
                value={selectedTouristPlace}
                onChange={handleTouristPlaceChange}
                onPaste={handleTouristPlaceChange}
                options={touristPlacesNames}
            />
        </div>
        <div className='touristDesti'>
          {(
              touristPlaces.map(place => (
                <div key={place.id} className='destinationTouristPlace' style={{display:place.photoUrl?(selectedTouristPlace? (place.name===selectedTouristPlace.label?'block':'none'):'block'):'none'}}>
                  <div className='imageSectionofdestinations' style={{backgroundImage:place.photoUrl? `url(${place.photoUrl})`:'', backgroundSize:'cover',}}>
                  </div>
                  <h2>{truncateName(place.name)}</h2>
                  <p>{selectedCityName}, {selectedCountryName}</p>
                  <button onClick={()=>handleExploreClick(selectedCountryName,selectedCityName,place.name.replace(/\(.*?\)/g, '').split(',')[0],place.photoUrl)} className='exploreButtonforTouristDesti'>Explore</button>
                </div>
              ))
            )
          }
        </div>
    </div>
  );
}

export default ExploreDestinations;
