import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsAndServices.css'

const Disclaimer = () => {

    const navigate = useNavigate();

    const navigateToWebsite=(event)=>{
        event.preventDefault();
        navigate({
            pathname: "/",
        });
      }
      

    return (
        <div className='termsAndServices'>
            <h2>Legal Disclaimer</h2>
            <p>
                The information provided by ADVENTROZ ("we," "us" or "our") on <span style={{color:'blue'}} onClick={navigateToWebsite}>www.adventroz.com</span>,
                (the "Site") and our mobile application is intended for general informational purposes only. While we make every reasonable effort to ensure the accuracy, 
                adequacy, validity, reliability, and availability of the information on the Site and our mobile application, we do not make any express or implied representation 
                or warranty of any kind regarding its completeness.
            </p>
            <h2>Tour Guide Services</h2>
            <p>
            Our tour guide services are provided on an "as is" basis, and we cannot be held responsible for any unforeseen circumstances that may occur during the tour. These circumstances 
            include but are not limited to accidents, injuries, theft, or loss of personal belongings. Clients assume responsibility for their safety and are expected to comply with all local
            laws and regulations during the tour. We strongly advise clients to consider purchasing travel insurance to safeguard against such occurrences.
            </p>
            <h2>Audiobooks Subscription</h2>
            <p>
            Our audiobooks, available through a subscription service, are intended for personal, non-commercial use only. While we strive to deliver high-quality content, we cannot guarantee that 
            the audiobooks will meet individual expectations or requirements. Unused subscription periods are non-refundable.
            </p>
            <h2>Third-Party Links and Services</h2>
            <p>
            The Site and our mobile application may contain links to other websites or third-party content. We do not conduct investigations, monitoring, or assessments of the accuracy, adequacy, validity, 
            reliability, availability, or completeness of such external links. Therefore, we do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information 
            provided by third-party websites linked through the site. Additionally, we do not assume responsibility for any transactions between users and third-party providers of products or services.
            </p>
            <h2>Changes to This Disclaimer</h2>
            <p>
            We may periodically update our Disclaimer and will notify users of any changes by posting the new Disclaimer on this page. Users are encouraged to review this Disclaimer periodically for any updates. 
            The changes are effective upon being posted on this page.
            </p>
            
            <h2>CONTACT Us.</h2>
            <p>If you have any questions about this Disclaimer, please contact us via:</p>
            <p>Email id - contact@adventroz.com</p>
            <p>Website: <span style={{color:'blue'}} onClick={navigateToWebsite}>www.adventroz.com</span></p>
            <p>Phone: +919953072372</p>
        </div>
    );
};

export default Disclaimer;
