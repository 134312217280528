import React from 'react';
import FinalPage from './HomePage/finalPage';
import NavBar from './NavBar';
import ExploreDestinations from './touristDestination';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ExploreBasedOnCityName = () => {
    const query = useQuery();
    const selectedCountryName=query.get('country') || 'No country specified';
    const selectedCityName=query.get('city') || 'No city specified';

    return (
        <div>
            <div id="home"><NavBar/></div>
            <div id="destination"><ExploreDestinations selectedCountryName={selectedCountryName} selectedCityName={selectedCityName}/></div>
            <div id="contact"><FinalPage/></div>
        </div>
    );
};

export default ExploreBasedOnCityName;
