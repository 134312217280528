import React from 'react';
import './PageThree.css';
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous"></link>


const Pagethree = () => {
  return (
    <section className='WhyChooseUs'>
        <div className='whyChooseHeading'>
          <h2>Unforgettable Tours with Experienced Guides</h2>
          <p>
            You can enjoy the culturally rich and historic destinations with a variety of 
            diverse tours. One of our experienced guides will guarantee you have an educational 
            and memorable journey. Simply open an audio book and instantly, youll learn all sorts 
            of juicy information about the spot.
          </p>
        </div>
        <div className='positivePoints'>
          <div id='pointOne' className='point'>
            <div className="audio-svg" id='diverseOpt'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-person-arms-up" viewBox="0 0 16 16">
                  <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                  <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
                </svg>
            </div>
            <span className='point-text'>
              <h3>Diverse Tour Options</h3>
              <p>Choose from a variety of tours tailored to your interests and preferences.</p>
            </span>       
          </div>
          <div id='pointTwo' className='point'>
            <div className="audio-svg" id='expGuide'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-award-fill" viewBox="0 0 16 16">
              <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864z"/>
              <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
              </svg>
            </div>
            <span className='point-text'>
              <h3>Experienced Guides</h3>
              <p>Our knowledgeable guides will enhance your tour experience with their expertise and insights.</p>
            </span>
          </div>

          <div id='pointThree' className='point'>
            <div className="audio-svg" id='audioAccess'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" className="bi bi-soundwave" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
              </svg>
            </div>
            <span className='point-text'>
              <h3>User-Friendly Audio Access</h3>
              <p>Enjoy convenient access to audio books that provide detailed information about each tourist spot.</p>
            </span>
          </div>
        </div>
    </section>
  );
};

export default Pagethree;
