import React, { useState } from 'react';
import 'boxicons/css/boxicons.min.css';
import styles from './ReviewForm.module.css'; // Import the CSS module
import axios from 'axios';
import ErrorMessage from './HomePage/ErrorMessage';


const ReviewForm = ({closeReviewForm, setIsNewTagsVisible}) => {
  const [ratingValue, setRatingValue] = useState(0);
  const allStars = Array(5).fill(null);
  const [opinion, setOpinion] = useState('');
  const [error,setError]=useState('');

  const handleStarClick = (index) => {
    setRatingValue(index + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    const touristMail=(storedIsLoggedIn)?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
    if(storedIsLoggedIn && opinion!==''){
      axios.post('/api/send-review-email', {
        touristMail,
        rating: ratingValue, 
        opinion
      })
      closeReviewForm();
    }else if(opinion===''){
      setError('opinion cant be empty');
    }
    else{
      setIsNewTagsVisible(true);
    }
    
  };

  return (
    <div className='book-tour-operator'>
      <div className='select-option-given-to-user'>
      <div onClick={closeReviewForm} className='closeLoginAndSignUpPopUp'>X</div>
        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><h2 style={{margin:'0px', marginBottom:'5px'}}>Add Your Review</h2></div>
          <form onSubmit={handleSubmit}>
          <div className={styles.rating}>
            {allStars.map((_, idx) => (
              <i
                key={idx}
                className={`bx ${ratingValue > idx ? 'bxs-star' : 'bx-star'} ${styles.star}`}
                onClick={() => handleStarClick(idx)}
              ></i>
            ))}
          </div>
          <textarea
            className={styles.textarea}
            name="opinion"
            cols="30"
            rows="5"
            placeholder="Your opinion..."
            onChange={(e) => setOpinion(e.target.value)}
          ></textarea>
          <div className={styles['btn-group']}>
            <button type="submit" className={`${styles.btn} ${styles.submit}`}>Submit</button>
            <button className={`${styles.btn} ${styles.cancel}`}>Cancel</button>
          </div>
        </form>
      </div>
      {error!=='' && <ErrorMessage message={error} onClose={()=>{setError('')}}/>}
    </div>
  );
};

export default ReviewForm;
