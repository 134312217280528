import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from './ErrorMessage';
import './PageFour.css';

const Pagefour = ({setIsNewTagsVisible}) => {
  const [xPosition, setXPosition] = useState(0);
  const [grantAccess, setGrantAccess] = useState(false);
  const [isExploreMoreVisible,setExploreMoreVisible]=useState(false);
  const [isCitySelectorDisable,setCitySelectorDisable]=useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState({});

  const [error,setError]=useState(null);

  const screenWidth = window.innerWidth;

  const navigate = useNavigate();

    useEffect(() => {

        const fetchCountries = async () => {
            try {
              const response = await fetch('/api/getAllCountries', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify()
              });
              const data = await response.json();
              setCountries(data.countries);
            } catch (error) {

            }
          };

        fetchCountries();
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
        if(storedIsLoggedIn || localStorage.getItem('isLoggedInByGoogle')){
            setGrantAccess(true);
        }
    }, []);
    
    const fetchCities = async (selectedOption) => {
        try{
            const countryCode=selectedOption.value;
            
            const response = await fetch('/api/getAllCitiesOfACountry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({countryCode})
            });
            const data = await response.json();
            setCities(data.cities);
        } catch (error) {
            setError("Something went wrong. Please retry");
        }
    };    
    
    const handleCountryChange =(selectedOption) => {
        setSelectedCountry(selectedOption);
        if(selectedOption!=null){
            setCitySelectorDisable(false);
        }
        fetchCities(selectedOption);
      };

      const handleCityChange = selectedOption => {
        setSelectedCity(selectedOption);
    };

  const handleRightButtonClick = () => {
    if(screenWidth>1310 && xPosition!==-210){
        setXPosition(xPosition-105);
    }
    else if(screenWidth>600 && screenWidth<1310 && xPosition!==-315){
        setXPosition(xPosition-105);
    }
    else if(screenWidth>430  && screenWidth<600 && xPosition!==-420){
        setXPosition(xPosition-105);
    }else if (screenWidth<430 && xPosition!==-420){
        setXPosition(xPosition-105);
    }
  };

  const handleLeftButtonClick = () => {
    if(xPosition!==0){
        setXPosition(xPosition+105);
    }
  };

  const toggleExploreMoreVisibility=()=>{
    setSelectedCity(null);
    setSelectedCountry(null);
    setCitySelectorDisable(true);
    // if(grantAccess){
    setExploreMoreVisible(!isExploreMoreVisible);
    // }else{
    //     setError('Please log in first');
    //     setIsNewTagsVisible(true);
    // }
  }
  
  const handleExploreClick = async (country,city,touristPlace) => {
    // if((localStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedInByGoogle'))){
    navigate(`/Explore?country=${encodeURIComponent(country)}&city=${encodeURIComponent(city)}&touristPlace=${encodeURIComponent(touristPlace)}`);
    // }else{
    //     setError('Please log in first');
    //     setIsNewTagsVisible(true);
    // }
  };

  const handelSubmitForExploreMore= async (event)=>{
    event.preventDefault();
    const country=selectedCountry.label;
    const city=selectedCity.label;
    navigate(`/ExploreMore?country=${encodeURIComponent(country)}&city=${encodeURIComponent(city)}`);
  }

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <section className='explore'>
        <div className='exploreDestinations'>
            <h2> ​Explore Destinations</h2>
            <p>Discover the beauty of the world with our audio tours</p>
            <div className='destinations'>
                <div className={`${xPosition === 0 ? 'disabled' : ''}button`} onClick={handleLeftButtonClick}><p style={{fontSize:'40px',marginTop:'-15px'}} className='left'>&lt;</p></div>

                <div className='destinationsData'>
                <div id='firstDestination' className='destination' style={{ transform: `translateX(${xPosition}%)`}}>                    
                    <div className='imageSectionofdestination'>
        
                    </div>
                    <h3 className='nameOfTheDestination'>Taj Mahal</h3>
                    <p>Agra, India</p>
                    <div className='corePoints'>
                        <div>Beauty</div>
                        <div>Love</div>
                        <div>Architecture</div>
                    </div>
                    <button className='exploreButton' onClick={() => handleExploreClick('India','Uttar Pradesh','Taj Mahal')}>Explore</button>
                </div>
                <div id='secondDestination' className='destination' style={{ transform: `translateX(${xPosition}%)`}}>
                    <div className='imageSectionofdestination'>

                    </div>
                    <h3 className='nameOfTheDestination'>Eiffel Tower</h3>
                    <p>Paris, France</p>
                    <div className='corePoints'>
                        <div>Culture</div>
                        <div>History</div>
                        <div>Cuisine</div>
                    </div>
                    <button className='exploreButton' onClick={() => handleExploreClick('France','Paris','Eiffel Tower')}>Explore</button>
                </div>
                <div id='thirdDestination' className='destination' style={{ transform: `translateX(${xPosition}%)`}}>
                    <div className='imageSectionofdestination'>

                    </div>
                    <h3 className='nameOfTheDestination'>Golden Pavilion</h3>
                    <p>Kyoto, Japan</p>
                    <div className='corePoints'>
                        <div>Architecture</div>
                        <div>Zen</div>
                        <div>Beauty</div>
                    </div>
                    <button className='exploreButton' onClick={() => handleExploreClick('Japan','Kyoto','Golden Pavilion')}>Explore</button>
                </div>
                <div id='fourthDestination' className='destination' style={{ transform: `translateX(${xPosition}%)`}}>
                    <div className='imageSectionofdestination'>

                    </div>
                    <h3 className='nameOfTheDestination'>Statue Of Liberty</h3>
                    <p>New York, USA</p>
                    <div className='corePoints'>
                        <div>Freedom</div>
                        <div>Immigration</div>
                        <div>Symbol</div>
                    </div>
                    <button className='exploreButton' onClick={() => handleExploreClick('USA','New York','Statue Of Liberty')}>Explore</button>
                </div>
                <div id='fifthDestination' className='destination' style={{ transform: `translateX(${xPosition}%)`}}>
                    <div className='imageSectionofdestination'>

                    </div>
                    <h3 className='nameOfTheDestination'>Amber Fort</h3>
                    <p>Jaipur, India</p>
                    <div className='corePoints'>
                        <div>Historic</div>
                        <div>Majestic</div>
                        <div>Fortress</div>
                    </div>
                    <button className='exploreButton' onClick={() => handleExploreClick('India','Jaipur','Amber Fort')}>Explore</button>
                </div>
            </div>
            <div className={`${(screenWidth>1310 && xPosition === -210) ? 'disabled' : (screenWidth>600 && xPosition === -315)?'disabled': (screenWidth>430 && xPosition === -420)?'disabled': (screenWidth<430 && xPosition === -420)?'disabled': ''}button`} onClick={handleRightButtonClick}><p  style={{fontSize:'40px',marginTop:'-15px'}} className='right'>&gt;</p></div>
                
        </div>
        <button className='exploreMore' onClick={toggleExploreMoreVisibility}>Explore More</button>
        </div>
        {isExploreMoreVisible && (
            <div className='exploreMorePopupWindow'>
                <div className='exploreMorePopup'>
                <div onClick={toggleExploreMoreVisibility} className='closeExploreMoreOption'>X</div>
                    <h2>Discover Your Next Adventure</h2>
                    <form onSubmit={handelSubmitForExploreMore}>
                        <div className='inputCountry' style={{ width: '300px' }}>
                            <Select 
                            placeholder="Select Country"
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            onPaste={handleCountryChange}
                            options={countries}
                            required/>
                            
                        </div>
                        <div className='inputCountry' style={{ marginTop: '10px', width: '300px' }}>
                            <Select isDisabled={isCitySelectorDisable}
                                placeholder="Select City"
                                value={selectedCity}
                                onChange={handleCityChange}
                                onPaste={handleCityChange}
                                options={cities}
                                required/>
                        </div>
                        <input className='exploreCountryAndStateOption' type="submit" value="Explore"/>
                    </form>

                </div>
            </div>
        )}
        {error && (<ErrorMessage message={error} onClose={handleCloseError}/>)}
    </section>
  );
};
export default Pagefour;
