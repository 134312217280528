import React from 'react';
import './PageTwo.css';

const Pagetwo = () => {
  const screenwidth=window.innerWidth;
  return (
    <section className="InformationAboutAudioBook">
      <div className='textSection'>
        <h2>Uncover Hidden Gems with our Audio Guide</h2>
        <p>
          Get a totally unique experience of your destination with our revolutionary audio guide. 
          Discover fascinating insights and stories of the historical and cultural richness in each 
          spooky travel destination with our incredible narrators.
        </p>
        <div className='listView'>
          <ul >
              <li>
                <svg style={{ width: '35px', height: '35px', marginTop:'10px' }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 3H16C16.55 3 17 3.45 17 4V16.17L14.83 14H4C3.45 14 3 13.55 3 13V4C3 3.45 3.45 3 4 3ZM4 2C2.9 2 2 2.9 2 4V13C2 14.1 2.9 15 4 15H15L18 18V4C18 2.9 17.1 2 16 2H4ZM6 7H12V8H6V7ZM6 9H14V10H6V9ZM6 11H12V12H6V11Z" fill="#000000"/>
                  </svg>

                <p>Engaging Narrations</p>
              </li>
              <li>
              <svg style={{ width: '30px', height: '30px', marginRight:'5px'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 2H6C4.897 2 4 2.897 4 4V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V8L13 2Z" fill="white" stroke="black"/>
                <path d="M14 2.5V8H19.5L14 2.5ZM7 11H17V13H7V11ZM7 15H14V17H7V15Z" fill="black"/>
                <circle cx="17" cy="17" r="5" stroke="black" stroke-width="2"/>
                <path d="M21 21L23 23" stroke="black" stroke-width="2"/>
              </svg>

                <p>Detailed Information</p>
              </li>
              <li>
                <svg style={{ width: '30px', height: '30px', marginRight:(screenwidth>1400)?'25px':(screenwidth>497)?'10px':'5px'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="10" stroke="black" stroke-width="2" fill="none"/>
                  <path d="M8 12L11 16L16 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <p>Convenient and Easy-to-Use</p>
              </li>
          </ul>
        </div>
      </div>
      <div className='imageSection'>
        <div className='imagePart'>
          <div className='imageCovering'>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Pagetwo;
