import React, { useState, useEffect } from 'react';
import './ErrorMessage.css';

const ErrorMessage = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="error-message">
      <span style={{color:'white'}}>{message}</span>
      <button className="close-button" onClick={onClose}>×</button>
      <div className="progress-bar"></div>
    </div>
  );
};

export default ErrorMessage;
