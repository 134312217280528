import React, { useState,useEffect } from 'react';
import './TourPlans.css';

function TourPlans({dashboardOpener}) {

    const [tourPlans, setTourPlans] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newPlan, setNewPlan] = useState({ startCity: '', place: '', price: '', startDate: '', endDate: '' });
    const [editingIndex, setEditingIndex] = useState(null);

    const userEmailAddress=(localStorage.getItem('isLoggedIn'))?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');

    const loadTourPlansFromBackend = async () => {
        try {
            const response = await fetch(`/api/load-tour-plans?email=${userEmailAddress}`);
            const data = await response.json();
            console.log(data)
            setTourPlans(data); // Set the retrieved tour plans into the state
        } catch (error) {
            console.error('Error loading tour plans:', error);
        }
    };

    // UseEffect to load data when the component mounts
    useEffect(() => {

        if (userEmailAddress) {
            loadTourPlansFromBackend(); // Load tour plans based on the tour guide's email
        }
    }, []);

    const handleChange = (e) => {
        setNewPlan({ ...newPlan, [e.target.name]: e.target.value });
    };

    const handleAddPlan = () => {
        if (editingIndex !== null) {
            const updatedPlans = [...tourPlans];
            updatedPlans[editingIndex] = newPlan; // Update the existing plan
            setTourPlans(updatedPlans);
            setEditingIndex(null); // Reset editing index
        } else {
            setTourPlans([...tourPlans, newPlan]);
            saveTourPlanToBackend(newPlan);
        }
        resetNewPlan();
        setShowModal(false);
    };

    const handleEditPlan = (index) => {
        setNewPlan(tourPlans[index]); // Set the plan data to be edited
        setEditingIndex(index); // Track which plan is being edited
        setShowModal(true); // Show the modal for editing
    };

    const resetNewPlan = () => {
        setNewPlan({ start_city: '', place: '', price: '', start_date: '', end_date: '' });
    };

    const saveTourPlanToBackend = async (plan) => {
        try {
            const response = await fetch('/api/add-tour-plan', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    startCity: plan.start_city,
                    place: plan.place,
                    price: plan.price,
                    startDate: plan.start_date,
                    endDate: plan.end_date,
                    tourGuideEmail: userEmailAddress, 
                    tourGuideName: localStorage.getItem('nameOfTheTourist')
                }),
            });

            if (response.ok) {
                console.log('Tour Plan Saved Successfully');
            } else {
                console.error('Failed to save tour plan');
            }
        } catch (error) {
            console.error('Error saving tour plan:', error);
        }
    };

    const deleteTourPlanFromBackend = async (planId) => {
        try {
            const response = await fetch(`/api/delete-tour-plan/${planId}`, {
                method: 'DELETE',
            });
    
            if (!response.ok) {
                throw new Error('Failed to delete tour plan');
            }
    
            const result = await response.json();
            console.log(result.message);
            return result;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    };
    
    const handleDeletePlan = async (index) => {
        const planId = tourPlans[index].id;
    
        try {
            const response = await deleteTourPlanFromBackend(planId);
    
            if (response && response.message === 'Tour plan deleted successfully') {
                const updatedPlans = tourPlans.filter((_, i) => i !== index);
                setTourPlans(updatedPlans);
            } else {
                console.error('Failed to delete plan from the backend');
            }
        } catch (error) {
            console.error('Error deleting plan:', error);
        }
    };
    
    

  return (
    <section id='tour-guide-review-page'>
        <div className='tour-guide-top-nav-bar'>
            <button 
                style={{marginTop:'20px'}}
                onClick={dashboardOpener} 
                className='tour-operator-dashboard-opener'
            >&#9776;</button>
        </div>
        <div id='review-profile-page'>
            <div className='profile-tour-guide'>
                <div className='profile-view-in-tour-guide'>
                {localStorage.getItem('profilePicture') ? (
                <img src={localStorage.getItem('profilePicture')} style={{height:'100%', width:'100%', objectFit:'cover'}} alt="Profile" className="profilePicture" />
                ) :(
                <svg xmlns="http://www.w3.org/2000/svg"  height="100" fill="white" className="bi bi-person-fill" viewBox="0 -2 17 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                </svg>
                )}
                </div>
                <div className='text-section'>
                    <h3 style={{color:'black', marginBottom:'-10px', fontSize:'24px', marginTop:'15px'}}>{localStorage.getItem('nameOfTheTourist')?`${localStorage.getItem('nameOfTheTourist')}`:`User`}</h3>
                    <p style={{color:'black', marginBottom:'-10px', fontSize:'14px'}}>{localStorage.getItem('status')?`${localStorage.getItem('status')}`:'N/A'}</p>
                    <p style={{color:'black', marginBottom:'-10px', fontSize:'14px'}}>No. of plans : {0}</p>
                </div>
                <div className='total-trips'>
                    Number of Trips: 0
                </div>
            </div>
            <div className='left-margin' style={{marginTop: '40px', display:'flex', alignItems:'center', gap:'10px'}}>
                <div style={{border:'1px solid grey', backgroundColor:'grey', width:'5%', height:'0px'}}></div>
                <h3 style={{color:'grey'}}>Tour Plans</h3>
                <div style={{border:'1px solid grey', backgroundColor:'silver', width:'70%', height:'0px'}}></div>
            </div>
            <div className='tour-plans-grid'>
                {tourPlans.map((plan, index) => (
                    <div key={index} className='tour-plan-box'>
                        <h4>
                            <span className='city-name'>{plan.start_city}</span>
                        </h4>
                        <p className='tourist-place'>
                            <strong>Places : {plan.place}</strong>
                        </p>
                        <p className='price'>Price: ${plan.price}</p>
                        <p>
                            Start: <strong>{new Date(plan.start_date).toLocaleString('en-US', { 
                                year: 'numeric', month: 'short', 
                                day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true 
                            })}</strong>
                        </p>
                        <p>
                            End: <strong>{new Date(plan.end_date).toLocaleString('en-US', { 
                                year: 'numeric', month: 'short', 
                                day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true 
                            })}</strong>
                        </p>
                        <div className='plan-actions'>
                            <button onClick={() => handleEditPlan(index)} className='edit-plans-button'>Change</button>
                            <button onClick={() => handleDeletePlan(index)} className='delete-button'>Delete</button>
                        </div>
                    </div>
                ))}
                {/* Button to add new plan */}
                <div className='add-plan-box' onClick={() => setShowModal(true)}>
                    <span>+</span>
                </div>
            </div>


                {/* Modal for Adding a New Tour Plan */}
                {showModal && (
                    <div className='modal'>
                        <div className='modal-content'>
                            <h3 className='modal-title'>Add Tour Plan</h3>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    name='startCity'
                                    placeholder='Start City'
                                    value={newPlan.startCity}
                                    onChange={handleChange}
                                    className='modal-input'
                                />
                            </div>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    name='place'
                                    placeholder='Place to Guide'
                                    value={newPlan.place}
                                    onChange={handleChange}
                                    className='modal-input'
                                />
                            </div>
                            <div className='input-group'>
                                <input
                                    type='number'
                                    name='price'
                                    placeholder='Price in USD'
                                    value={newPlan.price}
                                    onChange={handleChange}
                                    className='modal-input'
                                    style={{ width: '80%' }}
                                />
                            </div>
                            <div className='input-group'>
                                <label htmlFor='startDate' className='modal-label'>Start Date and Time</label>
                                <input
                                    type='datetime-local'
                                    id='startDate'
                                    name='startDate'
                                    value={newPlan.startDate}
                                    onChange={handleChange}
                                    className='modal-input'
                                />
                            </div>
                            <div className='input-group'>
                                <label htmlFor='endDate' className='modal-label'>End Date and Time</label>
                                <input
                                    type='datetime-local'
                                    id='endDate'
                                    name='endDate'
                                    value={newPlan.endDate}
                                    onChange={handleChange}
                                    className='modal-input'
                                />
                            </div>
                            <div className='modal-buttons'>
                                <button onClick={handleAddPlan} className='modal-save-button'>Save</button>
                                <button onClick={() => setShowModal(false)} className='modal-cancel-button'>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
                </div>


    </section>
  )
}

export default TourPlans
