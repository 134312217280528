import React,{useState, useEffect} from 'react'
import NavBar from './NavBar';
import FinalPage from './HomePage/finalPage';

function UserReview() {

    const [isNewTagsVisible,setIsNewTagsVisible]=useState(false);

    const [userReviews,setUserReviews]=useState([]);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        const fetchReviews = async () => {
            try {
                const response = await fetch(`/api/get-review`,{
                    method: 'POST',
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);
                setUserReviews(data);

            } catch (error) {
                
            }
        };

        fetchReviews();
    })

    const renderStars = (rating) => {
        const totalStars = 5;
        const filledStars = '★'.repeat(rating);
        const emptyStars = '☆'.repeat(totalStars - rating);
        return filledStars + emptyStars;
      };

  return (
    <section>
      <NavBar isNewTagsVisible={isNewTagsVisible} setIsNewTagsVisible={setIsNewTagsVisible} />
        <div id='reviews-box' style={{marginTop:'100px', marginLeft:screenWidth>430?'5%':'6%'}}>
        {userReviews.length !== 0 && (<h1 style={{ width: screenWidth>=500?'100%':'320px', textAlign: 'center', gridColumn: screenWidth>=1200?'span 3':screenWidth>=500?'span 2':'span 1', }}>User Reviews</h1>)}
        {userReviews.map((review, index) => (
            <div key={index} className="review-card" style={{width:screenWidth<430?'320px':''}}>
                <div style={{display:'flex',alignItems:'center', gap:'20px'}}>
                <div style={{height:'50px', width:'50px'}} className='profile-view-in-tour-guide'>
                    {review.profilePicture ? (
                    <img src={review.profilePicture} style={{height:'100%', width:'100%', objectFit:'cover'}} alt="Profile" className="profilePicture" />
                    ) :(
                    <svg xmlns="http://www.w3.org/2000/svg"  height="50" fill="white" className="bi bi-person-fill" viewBox="0 -2 17 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                    </svg>
                    )}
                </div>
                    <h3 style={{color:'rgb(202, 53, 190)'}}>{review.email}</h3>
                </div>
                <p>{review.opinion}</p>
                <p className="rating">Rating: <span className='rating-stars'>{renderStars(review.review)}</span></p>
            </div>
        ))}
        </div>
        {userReviews.length === 0 && (<div style={{display:'flex',alignItems:'center',justifyContent:"center", width:'100%'}}>
                <p style={{fontSize:'40px', fontWeight:'bold'}}>No reviews yet</p>
        </div>)}
      <FinalPage setIsNewTagsVisible={setIsNewTagsVisible}/>
    </section>
  )
}

export default UserReview
